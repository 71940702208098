export const LibsOfTikTokConfig = {
    ownerProfileId: '58b26b455db7003444b314037ed76d4b14aaf5a5',
    appId: 'LIBS_OF_TIKTOK',
    name: 'Libs of TikTok',
    logo: 'Libs of TikTok',
    appUrl: 'libtok.app',
    favicon: 'libtok-favicon.ico',
    pages: [
      'LANDING',
      'EXPLORE',
      'PROFILE',
      'MESSENGER',
    ],
    type: 'SINGLE',
    forceSso: true,
    allowProfileEvents: 'true',
    landing: {
      page: 'LANDING',
      header: 'DAT CRINGE',
      subHeader: 'LIBS TAKING L\'S IN REAL TIME',
      cta: {
        primary: {
          text: 'Explore',
          type: 'FUNCTION',
          color: 'primary'
        },
        // secondary: {
        //   type: 'REF',
        //   ref: 'apply-founding-creator',
        //   text: 'Influencers',
        //   color: 'secondary',
        //   isBlock: true
        // },
      },
      items: [
        {
          type: 'CONTACT',
          id: 'contact-us',
          isInline: true,
          email: true,
          company: false,
          body: false
        },
        {
          type: 'FOOTER',
          id: 'footer',
        },
      ]
    },
    pageConfigs: [
      {
        key: 'PROFILE',
        isAuthorized: true,
        tabs: [
          'POSTS',
          'EVENTS',
          'FRIENDS',
          'GROUPS',
        ],
      },
      {
        key: 'EXPLORE',
        isAuthorized: false,
        tabs: [
          'EVENTS',
        ],
      },
      {
        key: 'EVENT',
        isAuthorized: true,
        tabs: [],
      },
      {
        key: 'GROUP',
        isAuthorized: true,
        hasEvent: true,
        tabs: [],
      },
    ],
    event: {
      type: 'SINGLE_APP',
      eventId: '2020',
      name: 'Speakeasy Social',
      description: 'The last bar open in quarantine',
      labelOverrideName: "Video",
      labelOverridePluralName: "Videos",
      showHeader: false,
      maxPeers: 1000000,
      maxPartySize: 9,
      viewerTypes: {
        allowSolo: true,
        allowParty: true,
        allowFrontRow: false,
        allowHost: true
      }
    },
    theme: {
      components: {
        page: {
          backgroundColor: 'secondary',
          altBackgroundColor: 'octonary',
          backgroundTransparency: 'octonary',
        },
        navBar: {
          backgroundColor: 'quinary',
          logoColor: 'secondary',
          thumbnailContainerColor: 'octonary',
          iconColor: 'primary',
          iconBackgroundColor: 'secondary',
          borderColor: 'secondary',
        },
        sideBar: {
          fontColor: 'secondary',
          backgroundColor: 'quinary',
          itemComponentColorPassive: 'quinary',
          itemComponentColorActive: 'primary',
          itemBackgroundColorPassive: 'quinary',
          itemBackgroundColorActive: 'quinary',
          itemColorPassive: 'secondary',
          itemColorActive: 'secondary',
        },
        hero: {
          titleColor: 'quaternary',
          descriptionColor: 'tertiary',
          bottomBackgroundColor: 'secondary',  
          badgeBackgroundColor: 'octonary',
          badgeFontColor: 'secondary',
          borderColor: 'senary',
          profileImgBackgroundColor: 'octonary',
          profileImgColor: 'secondary'
        },
        gallery: {
          backgroundColor: 'secondary',
          borderColor: 'senary',
          tileLowerBackgroundColor: 'octonary',
          viewCountColor: 'quaternary',
          menuItemColorPassive: 'quaternary',
          menuItemColorActive: 'tertiary',
          menuItemBadgeBackgroundColorPassive: 'quaternary',
          menuItemBadgeBackgroundColorActive: 'tertiary',
          menuItemBadgeColorPassive: 'tertiary',
          menuItemBadgeColorActive: 'octonary',
        }
      },  
      color: {
        primary: '#cf1b30', // primary red
        secondary: '#f7f7f7', // offwhite
        tertiary: '#69747C', // slate
        quaternary: '#545454', // dark gray
        quinary: '#eb4559', // red
        senary: '#eb455934', // red w transparency
        septenary: '#cf1b3034', // primary w transparency
        octonary: '#7D838640', // grey w transparency
        nonary: '#8666AD', // purple
  
      },
    type: {
        primary: 'Oswald',
        secondary: 'Oswald',
      },
    },
  }
  