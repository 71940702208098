import { gql } from '@apollo/client'

export const GET_CRYPTO_PROFILE_QUERY = gql`
  query getCryptoProfile($request: GetCryptoProfileRequest) {
    getCryptoProfile(request: $request) {
        results {
            cryptoProfileId
            provider
            profileId
            accountIds
            appId
        }
        metadata {
            count
            skip
            limit
        }
    }
  }
`
