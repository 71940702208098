import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import CryptoService from '@tesseract-media/tesseract-crypto'
import { Card, CardText, CardBody, CardTitle } from 'reactstrap'
import BaseFormComponent from '../../shared/base-form'
import { TEMPLATE_SUBMISSION_MUTATION } from './mutations'  
import { GET_SUBMISSION_QUERY } from './queries'
import LoadingComponent from '../loading-component'
import { GET_CRYPTO_PROFILE_QUERY } from '../crypto-wallet-link-component/queries'
import { GET_TEMPLATE_QUERY } from '../active-template-component/queries'

const provider = process.env.REACT_APP_STAGE === 'production' ? 'WEB3' : 'WEB3_TEST'


const mapPollForm = (pollDetails, submission) => {
    const { label, inputType, inputOptions, answerType } = pollDetails

    const options = inputOptions.map(opt => (
        {
            key: opt.key,
            value: opt.key,
            label: opt.label
        })
    )

    let defaultAnswer = undefined
    if (submission && submission.answer) {
        if (inputType === 'RADIO') {
            defaultAnswer = submission.answer.optionKey
        } else {
            defaultAnswer = submission.answer[answerType]
        }
    }

    const form = {
        isInline: false,
        showErrors: true,
        fields: {
            answer: {
                dataType: answerType,
                type: inputType.toLowerCase(),
                name: 'answer',
                label,
                options,
                showLabel: true,
                defaultValue: defaultAnswer,
                placeholder: '',
                isRequired: true,
                minLength: 1,
                maxLength: 200,
                xs: 12,
            }
        },
        submit: {
          isBlock: true,
          canReSubmit: true,
        },
    }

    return form
}

const PollComponent = ({ pollDetails, submission, cryptoAccount, template }) => {

    const {
        templateId,
        templateItemId,
        principalId,
        answerType,
        inputType
    } = pollDetails

    const [putTemplateSubmission] = useMutation(TEMPLATE_SUBMISSION_MUTATION)

    const [pollForm, setPollForm] = useState(undefined)

    const generateCryptoResponse = async (pollTitle, pollAnswer) => {
        const { contractAddress } = template
        const { accountIds } = cryptoAccount

        const balance = await CryptoService.getBalance(provider, accountIds[0], contractAddress)

        const message = JSON.stringify({
            title: pollTitle, 
            answer: pollAnswer,
            balance
        })

        const signedResponse = await CryptoService.generateSubmission(provider, accountIds[0], contractAddress, message)

        return signedResponse
    }


    const submitPoll = async ({ answer }) => {

        const signedResponse = await generateCryptoResponse(pollDetails.label, answer)
        const { address, msg, sig, version } = signedResponse.verification

        const payload = {
            variables: {
                request: {
                    templateId,
                    templateItemId,
                    principalId,
                    answer: `${answer}`,
                    answerType,
                    inputType,
                    address, 
                    msg, 
                    sig, 
                    version: `${version}`
                },
            },
            refetchQueries: [{
                query: GET_SUBMISSION_QUERY,
                variables: {
                    request: {
                        templateItemId,
                    },
                },
                fetchPolicy: 'network-only',
            }]
        }
        
        putTemplateSubmission(payload)   
        setPollForm(undefined) 
    }

    useEffect(() => {
        const mappedForm = mapPollForm(pollDetails, submission)
        setPollForm(mappedForm)
    }, [pollDetails, submission])


    return (
        <div className="p-3" >
            <Card className="bg-secondary text-left" >
                <CardBody>
                    <CardTitle tag="h5">Event Poll</CardTitle>
                    {/* <CardSubtitle tag="h6" className="mb-2 text-muted">Card subtitle</CardSubtitle> */}
                    <CardText>
                        { pollForm 
                            ? <BaseFormComponent form={pollForm} submit={submitPoll} />
                            : <LoadingComponent isInline />
                        }
                    </CardText>
                </CardBody>
            </Card>
        </div>
      )
}


const ComponentWithCryptoProfile = (props) => {
    const { profileId, render } = props
    const queryVariables = {
        request: {
            profileId
        }
    }

    const { data: cryptoProfileResult , loading: loadCryptoProfile } = useQuery(GET_CRYPTO_PROFILE_QUERY, { variables: queryVariables })
    
    if (loadCryptoProfile) return <LoadingComponent isInline />

    let cryptoAccount = undefined

    if(cryptoProfileResult.getCryptoProfile && cryptoProfileResult.getCryptoProfile.results.length > 0) {
        cryptoAccount = cryptoProfileResult.getCryptoProfile.results[0]
    }

    return render({ ...props, cryptoAccount })
}

const ComponentWithTemplateQuery = (props) => {

    const { pollDetails, render } = props

    const queryVariables = {
        request: {
            templateId: pollDetails.templateId
        }
    }

    const { data, loading: loadingTemplate } = useQuery(GET_TEMPLATE_QUERY, { variables: queryVariables })
    
    if (loadingTemplate) return <LoadingComponent isInline />

    let template = undefined

    if(data.getTemplate && data.getTemplate.results.length > 0) {
        template = data.getTemplate.results[0]
    }

    return render({ ...props, template })

}



const PollComponentWithQuery = (props) => {

    const { pollDetails } = props

    const { submissionId, templateItemId } = pollDetails

    const variables = {
        request: {
            submissionId,
            templateItemId
        },
    }
    
    const { data, loading } = useQuery(GET_SUBMISSION_QUERY, { variables })
    
    if (loading) {
        return <LoadingComponent isInline />
    } else {
        const { metadata, results } = data.getSubmission
        const submission = metadata.count > 0 ? results[0] : {}
        return (
            <ComponentWithTemplateQuery {...props}
                render={({ template }) => (
                    <ComponentWithCryptoProfile {...props}
                    render={({ cryptoAccount }) => (
                        <PollComponent {...props} submission={submission} cryptoAccount={cryptoAccount} template={template} />
                    )}
                />
                    )}
            />
        )
    }
    
}

export default PollComponentWithQuery
