import React, { useState } from 'react'
import EventNavigationComponent from '../../../../../event-navigation-component'
import {GET_EVENT_STATE_QUERY} from './queries'
import { Button } from 'reactstrap'
import LoadingComponent from '../../../../../loading-component'
import { useQuery } from '@apollo/client'


const HostEventNavigator = (props) => {
    const { appConfig, event, partyDetails, profile, goToActiveEvent, eventState, toggle, isOpen } = props
    const { eventId } = event
    const { parties } = partyDetails
    const { profileId } = profile

    const { viewers } = eventState

    const viewersParty = {
        partyId: 'VIEWERS',
        peers: viewers,

    }

    const mappedEventState = {
        parties: [
            ...parties,
            ...eventState.parties,
            viewersParty
        ]
    }    

    return (
        <EventNavigationComponent
            isOpen={isOpen}
            toggle={toggle}
            appConfig={appConfig}
            currentPartyId={""}
            eventId={eventId}
            goToSpecifiedParty={goToActiveEvent}
            myProfileId={profileId}
            parties={mappedEventState.parties}
            key={0}
        />
    )
}


const HostEventNavigatorWithQuery = (props) => {

    const { event: { eventId, appId } } = props

    const variables = {
        request: {
          appId,
          eventId
        },
    }

    const {data, loading} = useQuery(GET_EVENT_STATE_QUERY, { variables, fetchPolicy: "network-only"  })

    
    if (loading) {
        return (<LoadingComponent isInline />)
    } else if (data) {
        // const dataKeys = Object.keys(data)

        const { getEventState } = data

        return (
            <HostEventNavigator
              {...props}
              eventState={getEventState}
            />
        )
    } else {
        return (<>nope</>)
    }
}



const HostEventNavigatorWithToggle = (props) => {

    const { event } = props

    const [isOpen, setIsOpen] = useState(false)

    const toggle = () => {
        setIsOpen(!isOpen)
    }

    return (
        <>

            { event.isHost && (<Button onClick={toggle}>Nav</Button>) }

            {
                isOpen && (
                    <HostEventNavigatorWithQuery
                    {...props}
                    isOpen={isOpen}
                    toggle={toggle}
                    />
                )
            }

        </>
    )

}



export default HostEventNavigatorWithToggle
