import { createGlobalStyle } from 'styled-components'

export const GlobalStyles = createGlobalStyle`

    body {
        background: ${({ theme }) => theme.components.page.backgroundColor };
        color: ${({ theme }) => theme.color.quaternary};
        font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Helvetica, Arial, sans-serif;
        height: 100vh;
    }

    #root {
      height: 100%;
    }



    :root {
        --amplify-primary-color: ${({ theme }) => theme.color.primary};
        --amplify-primary-tint: ${({ theme }) => theme.color.primary};
        --amplify-primary-shade: ${({ theme }) => theme.color.primary};
    }

    .font-logo {
        font-family: ${({ theme }) => theme.type.primary};
        color: ${({ theme }) => theme.components.navBar.logoColor };
        font-size: 22px;
    }

    .font-primary {
        font-family: ${({ theme }) => theme.type.primary};
    }

    .font-secondary {
        font-family: ${({ theme }) => theme.type.secondary};
    }

    .color-primary {
        color: ${({ theme }) => theme.color.primary};
    }

    .color-secondary {
      color: ${({ theme }) => theme.color.secondary};
    }

    .color-tertiary {
      color: ${({ theme }) => theme.color.tertiary};
    }

    .color-quaternary {
      color: ${({ theme }) => theme.color.quaternary};
    }

    .bg-primary {
      background: ${({ theme }) => theme.color.primary} !important;
      color: ${({ theme }) => theme.color.secondary};
    }

    .bg-secondary {
        background: ${({ theme }) => theme.color.secondary} !important;
    }
    
    .bg-tertiary {
      background: ${({ theme }) => theme.color.tertiary} !important;
    }
    
    .bg-quaternary {
      background: ${({ theme }) => theme.color.quaternary} !important;
    }

    .text-primary {
        color: ${({ theme }) => theme.color.primary} !important;
    }

    .text-secondary {
        color: ${({ theme }) => theme.color.secondary} !important;
    }

    .btn-primary {
        background-color: ${({ theme }) => theme.color.primary};
        color: ${({ theme }) => theme.color.secondary};
        border-color: ${({ theme }) => theme.color.primary};
    }
    .btn-primary:hover {
        background-color: ${({ theme }) => theme.color.quaternary};
        color: ${({ theme }) => theme.color.secondary};
        border-color: ${({ theme }) => theme.color.quaternary};
    }

    .btn-primary.disabled {
        background-color: ${({ theme }) => theme.color.secondary};
        color: ${({ theme }) => theme.color.quaternary};
        border-color: ${({ theme }) => theme.color.quaternary};
    }

 
    .btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
        background-color: ${({ theme }) => theme.color.secondary};
        color: ${({ theme }) => theme.color.primary};
        border-color: ${({ theme }) => theme.color.primary};
    }

    .btn-outline-primary {
        background-color: ${({ theme }) => theme.color.secondary};
        color: ${({ theme }) => theme.color.primary};
        border-color: ${({ theme }) => theme.color.primary};
    }
    .btn-outline-primary:hover {
        background-color: ${({ theme }) => theme.color.quaternary};
        color: ${({ theme }) => theme.color.secondary};
        border-color: ${({ theme }) => theme.color.quaternary};
    }


    .btn-secondary {
        background-color: ${({ theme }) => theme.color.secondary};
        color: ${({ theme }) => theme.color.primary};
        border-color: ${({ theme }) => theme.color.primary};
    }
    .btn-secondary:hover {
        background-color: ${({ theme }) => theme.color.quaternary};
        color: ${({ theme }) => theme.color.secondary};
        border-color: ${({ theme }) => theme.color.quaternary};
    }

    .btn-tertiary {
      background-color: ${({ theme }) => theme.color.tertiary};
      color: ${({ theme }) => theme.color.secondary};
      border-color: ${({ theme }) => theme.color.secondary};
    }
    .btn-tertiary:hover {
        background-color: ${({ theme }) => theme.color.quaternary};
        color: ${({ theme }) => theme.color.secondary};
        border-color: ${({ theme }) => theme.color.quaternary};
    }


    .cursor-pointer:hover {
        cursor: pointer;
      }
      

    .nav-link {
        color: ${({ theme }) => theme.color.primary};
    }
    .nav-link:hover {
        color: ${({ theme }) => theme.color.quaternary};
    }

    .nav-pills .nav-link.active, .nav-pills .show>.nav-link {
        background-color: ${({ theme }) => theme.color.primary};
        color: ${({ theme }) => theme.color.secondary};
    }

    .hero-item {
        color: ${({ theme }) => theme.color.primary};
    }

    .jumbotron {
        color: ${({ theme }) => theme.color.secondary};
        background-color: ${({ theme }) => theme.color.primary};
    }
  
    .sc-chat-window {
        z-index: 1000;
    }
    .sc-launcher {
        display: none;
    }

    .sc-header {
        background-color: ${({ theme }) => theme.color.tertiary};
    }

    .sc-header--close-button:hover {
        background-color: ${({ theme }) => theme.color.secondary};
    }


    .toast-header {
        color: ${({ theme }) => theme.color.secondary};
        background-color: ${({ theme }) => theme.color.primary};
    }


    .toast-body {
        color: ${({ theme }) => theme.color.secondary};
        background-color: ${({ theme }) => theme.color.primary};
    }


    .list-group-item {
        background-color: ${({ theme }) => theme.color.secondary};
        border: ${({ theme }) => theme.color.primary} solid 2px;
    }




    .modal-header {
        background-color: ${({ theme }) => theme.color.secondary};
    }

    .modal-body {
        background-color: ${({ theme }) => theme.color.secondary};
    }

    .modal-footer {
        background-color: ${({ theme }) => theme.color.secondary};
    }

    .modal-dialog {
        margin-top: 30vh
    }




.block
{
  position: relatve;
  width: 200px;
  height: 200px;
  margin: auto 0;
}

.shape{
  width: 100px;
  height: 100px;
}

.cube{
  position: relative;
  transition:transform .5s;
  transform-style: preserve-3d;
}

.cube.outer{
  width: 200px;
  height: 200px;
  transform-style: preserve-3d;
  animation: spin 4s infinite cubic-bezier(.67,.03,.31,.98);
}

.cube.outer > .cube{
  width: 100px;
  height: 100px;
  transform: translateX(50px) translateY(50px);
  animation: spin-inner 4s infinite cubic-bezier(.67,.03,.31,.98);
}

.side{
  position: absolute;
  width: 100%;
  height: 100%;
  /*backface-visibility: hidden;*/
  
  font-size: 30px;
  line-height: 100px;
  text-align: center;
  opacity: .9;
  transition: all .2s linear;
  /* border: 1px solid #015477; */
  border: 1px solid ${({ theme }) => theme.color.primary};


  background: ${({ theme }) => theme.color.secondary}8B;
  box-shadow: inset 0 0 100% 0 rgba(1, 84, 119, .7);
  box-sizing: border-box;
}

.outer > .cube .side {  
  border: 1px solid ${({ theme }) => theme.color.primary};

  background: ${({ theme }) => theme.color.secondary};
}

.side.left{
  transform: translateX(-50px) rotateY(-90deg);  
}

.cube.outer > .side.left{
  transform: translateX(-100px) rotateY(-90deg);  
}

.side.right{
  transform: translateX(50px) rotateY(90deg);  
}

.cube.outer > .side.right{
  transform: translateX(100px) rotateY(90deg);  
}

.side.top{ 
  transform: translateY(-50px) rotateX(90deg);  
}

.cube.outer > .side.top{
  transform: translateY(-100px) rotateX(90deg);  
}

.side.bottom{ 
  transform: translateY(50px) rotateX(-90deg);  
}

.cube.outer > .side.bottom{
  transform: translateY(100px) rotateX(-90deg);  
}

.side.front{
  transform: translateZ(50px); 
}

.cube.outer > .side.front{
  transform: translateZ(100px);  
}

.side.back{  
  transform: translateZ(-50px) rotateX(180deg);
}

.cube.outer > .side.back{
  transform: translateZ(-100px) rotateX(180deg); 
}




@keyframes spin {  
  0% {
    transform: rotateX(0deg) rotateY(0deg);
  }
  33.333% {
    transform: rotateX(-35deg) rotateY(-45deg);
  }
  66.666% {
    transform: rotateX(-35deg) rotateY(45deg);
  }  
  100% {
    transform: rotateX(0deg) rotateY(0deg);
  }
}

@keyframes spin-inner {
  0% {
    transform: translateX(50px) translateY(50px) rotateY(0deg) rotateX(90deg);
  }
  33.333% {
    transform: translateX(50px) translateY(50px) rotateY(90deg) rotateX(90deg);
  }
  66.666% {
    transform: translateX(50px) translateY(50px) rotateY(-90deg) rotateX(90deg);
  }
  100% {
    transform: translateX(50px) translateY(50px) rotateY(0deg) rotateX(90deg);
  }
}



#dotCanvas {
  width: 100%;
  height: 100%;
}



.ais-Hits-list {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.ais-Hits-item {
  float: left;
}


`
