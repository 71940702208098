import React, { useState } from 'react'
import { Button } from 'reactstrap'
import BaseFormComponent from '../base-form'


const validateForms = (forms, formValidation, values) => {
    let isValid = true

    const formKeys = forms.map(f => f.key)

    let formsToValidate = []

    formKeys.map(formKey => {
        const formDetails = forms.find(f => f.key === formKey)
        const { prerequisite, blacklist } = formDetails
        const prerequisiteKeys = Object.keys(prerequisite)
        const blacklistKeys = Object.keys(blacklist)

        let showForm = true
        prerequisiteKeys.map(prerequisiteKey => {
            const fieldKeys = Object.keys(formDetails.prerequisite[prerequisiteKey])
            fieldKeys.map(fieldKey => {
                const stateValue = values[fieldKey]
                const prerequisiteValue = formDetails.prerequisite[prerequisiteKey][fieldKey]
                if (stateValue !== prerequisiteValue) {
                    showForm = false
                }
                return fieldKey
            })
            return prerequisiteKey
        })
        blacklistKeys.map(blacklistKey => {
            const fieldKeys = Object.keys(formDetails.blacklist[blacklistKey])
            fieldKeys.map(fieldKey => {
                const stateValue = values[fieldKey]
                const blacklistValue = formDetails.blacklist[blacklistKey][fieldKey]
                if (stateValue === blacklistValue) {
                    showForm = false
                }
                return fieldKey
            })
            return blacklistKey
        })
        if (showForm) {
            formsToValidate.push(formKey)
        }
        return formKey
    })

    formsToValidate.map(validFormKey => {
        if (!formValidation[validFormKey]) {
            isValid = false
        }
        return formsToValidate
    })

    return isValid
}

const CompoundFormComponent = ({ form, submit }) => {

    const [values, setValues] = useState({})

    // const [formState, setFormState] = useState(form)
    const [formState] = useState(form)

    const [formValidation, setFormValidation] = useState({})

    const [showError, setShowError] = useState(false)

    const formKeys = formState.map(f => f.key)

    const updateValues = (vals) => setValues({ ...values, ...vals })

    const submitValidation = (validation) => setFormValidation({ ...formValidation, ...validation})

    const submitCompoundForm = () => {
        if (validateForms(formState, formValidation, values)) {
            submit(values)
        } else {
            setShowError(true)
        }
    }

    return (
        <>
            { formKeys.map(formKey => {
                const formDetails = formState.find(f => f.key === formKey)
                const { prerequisite, blacklist } = formDetails
                const prerequisiteKeys = Object.keys(prerequisite)
                const blacklistKeys = Object.keys(blacklist)

                let showForm = true
                prerequisiteKeys.map(prerequisiteKey => {
                    const fieldKeys = Object.keys(formDetails.prerequisite[prerequisiteKey])
                    fieldKeys.map(fieldKey => {
                        const stateValue = values[fieldKey]
                        const prerequisiteValue = formDetails.prerequisite[prerequisiteKey][fieldKey]
                        if (stateValue !== prerequisiteValue) {
                            showForm = false
                        }
                        return fieldKey
                    })
                    return prerequisiteKey
                })
                blacklistKeys.map(blacklistKey => {
                    const fieldKeys = Object.keys(formDetails.blacklist[blacklistKey])
                    fieldKeys.map(fieldKey => {
                        const stateValue = values[fieldKey]
                        const blacklistValue = formDetails.blacklist[blacklistKey][fieldKey]
                        if (stateValue === blacklistValue) {
                            showForm = false
                        }
                        return fieldKey
                    })
                    return blacklistKey
                })

                if (showForm) {
                    return (
                        <>
                            <p>{formDetails.label}</p>
                            <BaseFormComponent 
                                form={formDetails.form} 
                                submit={updateValues} 
                                formKey={formDetails.key}
                                submitValidation={submitValidation}
                            />
                        </>
                    )   
                } else {
                    return <></>
                }
            })}
            { showError &&
                <p style={{ color: 'red' }}>Fill in required fields</p>
            }
            <Button onClick={submitCompoundForm} >Submit</Button>
        </>
    )

    
}

export default CompoundFormComponent
