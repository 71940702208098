import React from 'react'
import { Col, Row } from 'reactstrap'
// import hash from 'object-hash'
import PartyComponent from '../../../../party-component'
import MasterStreamComponent from '../../../../stream-component/master'
import ViewerStreamComponent from '../../../../stream-component/viewer'
// import { getAppId } from '../../../../../utils/brand-silo'


const getAvailablePeers = (partyDetails, viewConfig) => {
    const availPeers = partyDetails ? partyDetails.peers.length : 0
    
    let partyColSize

    // Todo: figure out where this code should actually go
    // if (!viewConfig.isHostInFrontRowView) {
    //     partyColSize = {
    //         xs: 12,
    //         md: 12
    //     }
    // } else 
    if (availPeers >= 5) {
        partyColSize = {
            xs: 6,
            md: 3,
        }
    } else if (availPeers >= 3) {
        partyColSize = {
            xs: 6,
            md: 6,
        }
    } else {
        partyColSize = {
            xs: 12,
            md: 6,
        }
    }

    return partyColSize
}


const AlternativeViewComponent = ({ isHostInParty, isHost, partyDetails, masterDetails, localMedia, enableAudio, enableVideo, getNextParty }) => {

    if (isHost) {
        return (
            <MasterStreamComponent
                getPartyDetails={getNextParty}
                localMedia={localMedia}
                enableAudio={enableAudio}
                enableVideo={enableVideo}
                {...masterDetails}
            />
        )
    } else if (isHostInParty) {
        const hostPeer = partyDetails.peers.find(p => p.isHost)
        return (
            <ViewerStreamComponent 
                hideControls={true}
                isLiked={false} 
                peer={hostPeer} 
                index={100} 
                reportUser={() => {}} 
                hostProfileId={hostPeer.profileId} 
            />
        )
    } else {
        return (<> Not sure how you got here </>)
    }

}


const EventPartyComponent = (props) => {

    const { 
        event, localMedia, setCurrent, profile, partyDetails, isHostInParty,
        updateEventDetails, masterDetails,
        // appConfig, partyId, setMessengerContext,
        getNextParty, sessionData, loadingSession, isHost,
        currentViewConfig, enableAudio, enableVideo
    } = props


    const partyColSize = getAvailablePeers(partyDetails, currentViewConfig)

    if (sessionData && sessionData.startEventSession) {
        const { partyId: currentPartyId } = partyDetails
        const { partyId, peers, parties } = sessionData.startEventSession

        if (partyId !== currentPartyId && partyId !== "") {
            updateEventDetails(partyId, peers, parties)
        }
    }

    // const openMessengerContext = () => {
    //     const contextType = 'PARTY'
    //     const displayName = 'Party Chat'
    //     const isOpen = true
    //     const appId = getAppId()
    //     const contextHash = hash(`${appId}-${partyDetails.partyId}`)
    //     setMessengerContext(contextType, partyId, isOpen, displayName, contextHash)
    // }
        
    return (

        <Row >
            <Col xs={12} className="px-3" >

                { (isHost || isHostInParty) && (
                    <Row>
                        <Col xs={12} >
                            <AlternativeViewComponent
                                isHost={isHost}
                                isHostInParty={isHostInParty}
                                partyDetails={partyDetails}
                                myProfileId={profile.profileId}
                                localMedia={localMedia}
                                enableAudio={enableAudio}
                                enableVideo={enableVideo}
                                masterDetails={masterDetails}
                                getNextParty={getNextParty}
                            />
                        </Col>
                    </Row>
                )}

                { (profile && profile.profileId) &&
                    <Row className="mr-3" >

                        <PartyComponent
                            initialPartyDetails={sessionData}
                            hasLoaded={!loadingSession && sessionData}
                            setCurrent={setCurrent}
                            hasHeader={event.showHeader}
                            profile={profile}
                            partyDetails={partyDetails}
                            event={event}
                            partyColSize={partyColSize}
                        />

                        { !isHost && 
                            <Col key={profile.profileId} xs={partyColSize.xs} md={partyColSize.md} style={{ padding: '0px' }}>
                                <MasterStreamComponent
                                    getPartyDetails={getNextParty}
                                    localMedia={localMedia}
                                    enableAudio={enableAudio}
                                    enableVideo={enableVideo}
                                    {...masterDetails}
                                />
                            </Col>
                        }

                    </Row>
                }

            </Col>

            {/* <Col xs={12} md={1}>
                <EventPartyActions 
                    event={event} 
                    openMessengerContext={openMessengerContext} 
                    getNextParty={getNextParty} 
                    appConfig={appConfig}
                    partyDetails={partyDetails}

                    profile={profile}
                />
            </Col> */}

        </Row>
    )
}



export default EventPartyComponent
