import React from 'react'
import {
  Modal,
  ModalHeader,
  ModalBody,
  Button,
  Row,
  Col,
  ModalFooter
} from 'reactstrap'




const FrontRowInvitationModal = ({ isOpen, toggle }) => {

    
    const acceptAction = () => {
        console.log(" acceptAction ")
        toggle(true)
    }

    return (
        <Modal isOpen={isOpen} toggle={() => toggle(false)}>
            <ModalHeader toggle={() => toggle(false)}>Front Row Invitation</ModalHeader>
            <ModalBody>
                <Row >
                    <Col xs={12} > 
                        You've been invited to the front row
                    </Col>
                </Row>
                
            </ModalBody>
            <ModalFooter >
                <Button block color="primary" onClick={acceptAction} >Join</Button>
            </ModalFooter>
        </Modal>
    )
}

export default FrontRowInvitationModal
