import { gql } from '@apollo/client'

export const LINK_CRYPTO_PROFILE_MUTATION = gql`
    mutation linkCryptoProfile($request: LinkCryptoProfileRequest) {
        linkCryptoProfile(request: $request) {
            results {
                cryptoProfileId
                provider
                profileId
                accountIds
                appId
            }
            metadata {
                count
                skip
                limit
            }
        }
    }
`

