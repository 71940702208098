import { HotboxAppConfig } from '../config/hotbox'
import { TesseractAppConfig } from '../config/tesseract'
import { TesseractTVAppConfig } from '../config/tv'
import { SpeakeasyAppConfig } from '../config/speakeasy'
import { YottaChatAppConfig } from '../config/yotta'
import { NextStepAppConfig } from '../config/nextstep'
import { LibsOfTikTokConfig } from '../config/libs_of_tiktok'
import { BadgerConfig } from '../config/badger'


const mapComponentTheme = (original) => {
  const config = JSON.parse(JSON.stringify(original))
  const { color, components } = config.theme

  const componentKeys = Object.keys(components)

  const counter = {}

  componentKeys.map(componentKey => {
    const currentComponent = components[componentKey]
    const itemKeys = Object.keys(currentComponent)

    itemKeys.map(itemKey => {
      const colorKey = currentComponent[itemKey]
      config.theme.components[componentKey][itemKey] = color[colorKey]
      
      if (counter[colorKey]) {
        counter[colorKey]++
      } else {
        counter[colorKey] = 1
      }
      return itemKey
    })
    return componentKey
  })


  return config
}

export const getAppId = () => {
  const { href, pathname } = window.location

  let currentApp = ''

  if (href.includes('localhost')) {
    if (pathname.includes('hotbox')) {
      currentApp = 'HOTBOX'
    } else if (pathname.includes('libs_of_tiktok')) {
      currentApp = 'LIBS_OF_TIKTOK'
    } else if (pathname.includes('badger')) {
      currentApp = 'BADGER'
    } else if (pathname.includes('speakeasy')) {
      currentApp = 'SPEAKEASY'
    } else if (pathname.includes('yotta')) {
      currentApp = 'YOTTA'
    } else if (pathname.includes('tv')) {
      currentApp = 'TV'
    } else if (pathname.includes('nextstep')) {
      currentApp = 'NEXTSTEP'
    } else {
      currentApp = 'TESSERACT'
    }
  } else if (href.includes('tesseract.media')) {
    if (pathname.includes('hotbox')) {
      currentApp = 'HOTBOX'
    } else if (pathname.includes('libs_of_tiktok')) {
      currentApp = 'LIBS_OF_TIKTOK'
    } else if (pathname.includes('badger')) {
      currentApp = 'BADGER'
    } else if (pathname.includes('speakeasy')) {
      currentApp = 'SPEAKEASY'
    } else if (pathname.includes('yotta')) {
      currentApp = 'YOTTA'
    } else if (pathname.includes('tv')) {
      currentApp = 'TV'
    } else if (pathname.includes('nextstep')) {
      currentApp = 'NEXTSTEP'
    } else {
      currentApp = 'TESSERACT'
    }
  } else if (href.includes('tesseract.tv')) {
    currentApp = 'TV'
  } else if (href.includes('hotboxsocial')) {
    currentApp = 'HOTBOX'
  } else if (href.includes('speakeasy')) {
    currentApp = 'SPEAKEASY'
  } else if (href.includes('yotta')) {
    currentApp = 'YOTTA'
  } else if (href.includes('nextstep')) {
    currentApp = 'NEXTSTEP'
  } 

  return currentApp
}

export const getCurrentBrandConfig = () => {
  const currentApp = getAppId()


  let appConfig = {}

  switch (currentApp) {
    case 'TESSERACT':
      appConfig = TesseractAppConfig
      break
    case 'TV':
      appConfig = TesseractTVAppConfig
      break
    case 'LIBS_OF_TIKTOK':
      appConfig = LibsOfTikTokConfig
      break
    case 'BADGER':
      appConfig = BadgerConfig
      break
    case 'HOTBOX':
      appConfig = HotboxAppConfig
      break
    case 'SPEAKEASY':
      appConfig = SpeakeasyAppConfig
      break
    case 'YOTTA':
      appConfig = YottaChatAppConfig
      break
    case 'NEXTSTEP':
      appConfig = NextStepAppConfig
      break
    default:
      appConfig = TesseractAppConfig
  }

  const mappedConfig = mapComponentTheme(appConfig)
  return mappedConfig
}
