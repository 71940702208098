import React, { memo } from 'react'
import { DropTarget } from 'react-dnd'
import { Badge } from 'reactstrap'

const style = {
  height: '12rem',
  width: '100%',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  color: 'white',
  padding: '1rem',
  textAlign: 'center',
  fontSize: '1rem',
  lineHeight: 'normal',
  float: 'left',
  borderRadius: '15px',
}

export const DndPartyTarget = memo(function DndPartyTarget({ accepts, isOver, canDrop, connectDropTarget }) {

  const isActive = isOver && canDrop

  let backgroundColor = '#222'
  
  if (isActive) {
    backgroundColor = 'darkgreen';
  }
  else if (canDrop) {
    backgroundColor = 'darkkhaki';
  }

  // eslint-disable-next-line jsx-a11y/aria-role
  return connectDropTarget(<div ref={connectDropTarget} style={{ backgroundColor }} role="DndPartyTarget">
      {
        isActive 
          ? <span className="color-secondary">Release to drop</span> 
          : <span className="color-tertiary">Drag a box here</span>}
    </div>)
})


const PartyDropTarget = DropTarget(
  ['ANY'], 
  {
    drop(props, monitor) {
      const { partyId } = props
      props.onDrop({ ...monitor.getItem(), partyId })
    },
  }, 
  (connect, monitor) => ({
    connectDropTarget: connect.dropTarget(),
    isOver: monitor.isOver(),
    canDrop: monitor.canDrop()
  })
)(DndPartyTarget)


const DndPartyTargetWithDropTarget = (props) => {

  const { partyId, peers, maxPartySize = 4,  renderSources } = props
    
    return (
      <div className="bg-quaternary" style={style} >
  
        <p className="text-left d-flex">
          <Badge className="cursor-pointer">
            {' '}
            { partyId }
            {' '}
          </Badge>
  
          <span className="ml-auto ">
            { peers.length }
            /
            { maxPartySize }
          </span>
        </p>
  
        { renderSources(peers) }
  
        <PartyDropTarget {...props} />

      </div>
    )
}


export default DndPartyTargetWithDropTarget
