

export const TEMPLATE_ITEM_FORM = [
    {
        key: 1,
        prerequisite: {},
        blacklist: {},
        label: 'Template Metadata',
        form: {
            isInline: false,
            showErrors: true,
            fields: {

                type: {
                    dataType: 'STRING',
                    type: 'text',
                    name: 'type',
                    label: 'Item Type',
                    showLabel: true,
                    defaultValue: 'QUESTION',
                    placeholder: '',
                    isRequired: true,
                    disabled: true,
                    hidden: true,
                    xs: 12,
                    md: 6
                },
                displayType: {
                    dataType: 'STRING',
                    type: 'text',
                    name: 'displayType',
                    label: 'Display Type',
                    showLabel: true,
                    defaultValue: 'POLL',
                    placeholder: '',
                    isRequired: true,
                    disabled: true,
                    hidden: true,
                    xs: 12,
                    md: 6
                },
                shouldScore: {
                    dataType: 'boolean',
                    type: 'checkbox',
                    name: 'shouldScore',
                    label: 'Should Score',
                    showLabel: true,
                    defaultValue: true,
                    isRequired: true,
                    hidden: true,
                    xs: 12,
                    md: 6
                },
                label: {
                    dataType: 'STRING',
                    type: 'text',
                    name: 'label',
                    label: 'Question',
                    showLabel: true,
                    defaultValue: undefined,
                    placeholder: '',
                    isRequired: true,
                    disabled: false,
                    minLength: 1,
                    maxLength: 250,
                    xs: 12,
                },
                answerType: {
                    dataType: 'STRING',
                    type: 'radio',
                    name: 'answerType',
                    label: 'Answer Type',
                    showLabel: true,
                    options: [
                        {
                            value: 'STRING',
                            label: 'String'
                        },
                        {
                            value: 'BOOLEAN',
                            label: 'Boolean'
                        },
                        {
                            value: 'DECIMAL',
                            label: 'Number'
                        }
                    ],
                    defaultValue: undefined,
                    placeholder: '',
                    isRequired: true,
                    disabled: false,
                    xs: 12,
                    md: 6
                },

            },
            submit: {
                isBlock: true,
                canReSubmit: true,
                hideButton: true,
                autoUpdate: true
            },
        }
    },
    {
        key: 2,
        prerequisite: {
            1: {
                answerType: 'BOOLEAN'
            }
        },
        blacklist: {},
        form: {
            isInline: false,
            showErrors: true,
            fields: {
                inputType: {
                    dataType: 'STRING',
                    type: 'radio',
                    name: 'inputType',
                    label: 'Input Type',
                    showLabel: true,
                    options: [
                        {
                            value: 'TEXT',
                            label: 'Text Input'
                        },
                        {
                            value: 'RADIO',
                            label: 'Radio Input'
                        }
                    ],
                    defaultValue: 'RADIO',
                    placeholder: '',
                    isRequired: true,
                    disabled: true,
                    hidden: true,
                    xs: 12,
                    md: 6
                },

            },
            submit: {
                isBlock: true,
                canReSubmit: true,
                hideButton: true,
                autoUpdate: true
            },
        }
    },
    {
        key: 3,
        prerequisite: {},
        blacklist: {
            1: {
                answerType: 'BOOLEAN'
            }
        },
        form: {
            isInline: false,
            showErrors: true,
            fields: {
                inputType: {
                    dataType: 'STRING',
                    type: 'radio',
                    name: 'inputType',
                    label: 'Input Type',
                    showLabel: true,
                    options: [
                        {
                            value: 'TEXT',
                            label: 'Text Input'
                        },
                        {
                            value: 'RADIO',
                            label: 'Radio Input'
                        }
                    ],
                    defaultValue: 'TEXT',
                    placeholder: '',
                    isRequired: true,
                    disabled: false,
                    xs: 12,
                    md: 6
                },

            },
            submit: {
                isBlock: true,
                canReSubmit: true,
                hideButton: true,
                autoUpdate: true
            },
        }
    },
    {
        key: 4,
        prerequisite: {
            1: {
                answerType: 'BOOLEAN'
            },
            2: {
                inputType: 'RADIO',
            }
        },
        blacklist: {},
        label: 'Boolean Answer',
        form: {
            isInline: false,
            showErrors: true,
            fields: {
                answer: {
                    dataType: 'STRING',
                    type: 'radio',
                    name: 'answer',
                    label: 'Answer',
                    showLabel: true,
                    options: [
                        {
                            value: 'true',
                            label: 'True'
                        },
                        {
                            value: 'false',
                            label: 'False'
                        }
                    ],
                    defaultValue: undefined,
                    isRequired: true,
                    disabled: false,
                    xs: 12,
                },
            },
            submit: {
                isBlock: true,
                canReSubmit: true,
                hideButton: true,
                autoUpdate: true
            },
        }
    },

    {
        key: 5,
        prerequisite: {
            1: {
                answerType: 'STRING'
            },
            3: {
                inputType: 'TEXT',
            }
        },
        blacklist: {},
        label: 'Text Answer',
        form: {
            isInline: false,
            showErrors: true,
            fields: {
                answer: {
                    dataType: 'STRING',
                    type: 'text',
                    name: 'answer',
                    label: 'Answer',
                    showLabel: true,
                    defaultValue: '',
                    placeholder: '',
                    isRequired: true,
                    disabled: false,
                    minLength: 5,
                    maxLength: 250,
                    xs: 12,
                },
            },
            submit: {
                isBlock: true,
                canReSubmit: true,
                hideButton: true,
                autoUpdate: true
            },
        }
    },
    {
        key: 6,
        prerequisite: {
            1: {
                answerType: 'DECIMAL'
            },
            3: {
                inputType: 'TEXT',
            }
        },
        blacklist: {},
        label: 'Number Answer',
        form: {
            isInline: false,
            showErrors: true,
            fields: {
                answer: {
                    dataType: 'NUMBER',
                    type: 'number',
                    name: 'answer',
                    label: 'Answer',
                    showLabel: true,
                    defaultValue: '',
                    placeholder: '',
                    isRequired: true,
                    disabled: false,
                    minLength: 5,
                    maxLength: 250,
                    xs: 12,
                },
            },
            submit: {
                isBlock: true,
                canReSubmit: true,
                hideButton: true,
                autoUpdate: true
            },
        }
    },
    {
        key: 10,
        prerequisite: {
            1: {
                answerType: 'STRING'
            },
            3: {
                inputType: 'RADIO',
            }
        },
        blacklist: {},
        label: 'Multiple-Choice Text Options',
        form: {
            isInline: false,
            showErrors: true,
            fields: {
                A: {
                    dataType: 'STRING',
                    type: 'text',
                    name: 'A',
                    label: 'Option A',
                    showLabel: true,
                    defaultValue: '',
                    placeholder: '',
                    isRequired: true,
                    disabled: false,
                    minLength: 1,
                    maxLength: 150,
                    xs: 6,
                },
                B: {
                    dataType: 'STRING',
                    type: 'text',
                    name: 'B',
                    label: 'Option B',
                    showLabel: true,
                    defaultValue: '',
                    placeholder: '',
                    isRequired: true,
                    disabled: false,
                    minLength: 1,
                    maxLength: 150,
                    xs: 6,
                },
                C: {
                    dataType: 'STRING',
                    type: 'text',
                    name: 'C',
                    label: 'Option C',
                    showLabel: true,
                    defaultValue: undefined,
                    placeholder: '',
                    isRequired: false,
                    disabled: false,
                    minLength: 1,
                    maxLength: 150,
                    xs: 6,
                },
                D: {
                    dataType: 'STRING',
                    type: 'text',
                    name: 'D',
                    label: 'Option D',
                    showLabel: true,
                    defaultValue: undefined,
                    placeholder: '',
                    isRequired: false,
                    disabled: false,
                    minLength: 1,
                    maxLength: 150,
                    xs: 6,
                },
                E: {
                    dataType: 'STRING',
                    type: 'text',
                    name: 'E',
                    label: 'Option E',
                    showLabel: true,
                    defaultValue: undefined,
                    placeholder: '',
                    isRequired: false,
                    disabled: false,
                    minLength: 1,
                    maxLength: 150,
                    xs: 6,
                },
                F: {
                    dataType: 'STRING',
                    type: 'text',
                    name: 'F',
                    label: 'Option F',
                    showLabel: true,
                    defaultValue: undefined,
                    placeholder: '',
                    isRequired: false,
                    disabled: false,
                    minLength: 1,
                    maxLength: 150,
                    xs: 6,
                },
                answer: {
                    dataType: 'STRING',
                    type: 'radio',
                    name: 'answer',
                    label: 'Answer',
                    showLabel: true,
                    options: [
                        {
                            value: 'A',
                            label: 'Option A'
                        },
                        {
                            value: 'B',
                            label: 'Option B'
                        },
                        {
                            value: 'C',
                            label: 'Option C'
                        },
                        {
                            value: 'D',
                            label: 'Option D'
                        },
                        {
                            value: 'E',
                            label: 'Option E'
                        },
                        {
                            value: 'F',
                            label: 'Option F'
                        },
                    ],
                    defaultValue: '',
                    placeholder: '',
                    isRequired: true,
                    disabled: false,
                    xs: 12,
                    md: 6
                }

            },
            submit: {
                isBlock: true,
                canReSubmit: true,
                hideButton: true,
                autoUpdate: true
            },
        }
    },
    {
        key: 11,
        prerequisite: {
            1: {
                answerType: 'DECIMAL'
            },
            3: {
                inputType: 'RADIO',
            }
        },
        blacklist: {},
        label: 'Multiple-Choice Number Options',
        form: {
            isInline: false,
            showErrors: true,
            fields: {
                A: {
                    dataType: 'NUMBER',
                    type: 'number',
                    name: 'A',
                    label: 'Option A',
                    showLabel: true,
                    defaultValue: undefined,
                    placeholder: '',
                    isRequired: true,
                    disabled: false,
                    minLength: 1,
                    maxLength: 150,
                    xs: 6,
                },
                B: {
                    dataType: 'NUMBER',
                    type: 'number',
                    name: 'B',
                    label: 'Option B',
                    showLabel: true,
                    defaultValue: undefined,
                    placeholder: '',
                    isRequired: true,
                    disabled: false,
                    minLength: 1,
                    maxLength: 150,
                    xs: 6,
                },
                C: {
                    dataType: 'NUMBER',
                    type: 'number',
                    name: 'C',
                    label: 'Option C',
                    showLabel: true,
                    defaultValue: undefined,
                    placeholder: '',
                    isRequired: false,
                    disabled: false,
                    minLength: 1,
                    maxLength: 150,
                    xs: 6,
                },
                D: {
                    dataType: 'NUMBER',
                    type: 'number',
                    name: 'D',
                    label: 'Option D',
                    showLabel: true,
                    defaultValue: undefined,
                    placeholder: '',
                    isRequired: false,
                    disabled: false,
                    minLength: 1,
                    maxLength: 150,
                    xs: 6,
                },
                E: {
                    dataType: 'NUMBER',
                    type: 'number',
                    name: 'E',
                    label: 'Option E',
                    showLabel: true,
                    defaultValue: undefined,
                    placeholder: '',
                    isRequired: false,
                    disabled: false,
                    minLength: 1,
                    maxLength: 150,
                    xs: 6,
                },
                F: {
                    dataType: 'NUMBER',
                    type: 'number',
                    name: 'F',
                    label: 'Option F',
                    showLabel: true,
                    defaultValue: undefined,
                    placeholder: '',
                    isRequired: false,
                    disabled: false,
                    minLength: 1,
                    maxLength: 150,
                    xs: 6,
                },

                answer: {
                    dataType: 'STRING',
                    type: 'radio',
                    name: 'answer',
                    label: 'Answer',
                    showLabel: true,
                    options: [
                        {
                            value: 'A',
                            label: 'Option A'
                        },
                        {
                            value: 'B',
                            label: 'Option B'
                        },
                        {
                            value: 'C',
                            label: 'Option C'
                        },
                        {
                            value: 'D',
                            label: 'Option D'
                        },
                        {
                            value: 'E',
                            label: 'Option E'
                        },
                        {
                            value: 'F',
                            label: 'Option F'
                        },
                    ],
                    defaultValue: '',
                    placeholder: '',
                    isRequired: true,
                    disabled: false,
                    xs: 12,
                    md: 6
                }

            },
            submit: {
                isBlock: true,
                canReSubmit: true,
                hideButton: true,
                autoUpdate: true
            },
        }
    },
    
]
