import React, { useEffect, useState } from 'react'
import { useMutation } from '@apollo/client'
import EventPartyComponent from '../event-party'
import EventMediaComponent from '../event-media'
import { getAppId } from '../../../../../utils/brand-silo'
import { RECORD_ACTIVITY, START_EVENT_SESSION } from '../mutations'
import EventSidebar from '../event-sidebar'
import { Col, Row } from 'reactstrap'
import { EventContainer } from './styles'
import ActiveEventHeaderComponent from './active-event-header'
import ActiveEventSidebarComponent from './active-event-sidebar'
import EventChatComponent from './event-chat'
import FrontRowInvitationModal from './front-row-invite-modal'

const checkIfHostInParty = (peers) => {
    const host = peers.find(p => p.isHost)
    return !!host
}

const ActiveEventComponent = (props) => {

    const { 
        viewerType, 
        event, 
        partyDetails,
        profile,
        hostSelectedParty,
        pollDetails,
        goBack,
        hasStream,
        localMedia,
        appConfig,
        updateEventDetails,
        goToIngress
    } = props

    const isSolo = viewerType === 'SOLO'
    const isGroup = event.type === 'GROUP_EVENT'
    const isHost = viewerType === 'HOST'
    const isSingleApp = event.type === 'SINGLE_APP'

    const isParty = (viewerType === 'PARTY' || viewerType === 'FRONT_ROW')

    const shouldSyncStream = isParty

    const appId = getAppId()

    const isHostInParty = checkIfHostInParty(partyDetails.peers)

    const isYoutubeStream = event.streamType === 'YOUTUBE'

    const showMedia = isYoutubeStream || (hasStream && (isSolo || (!isGroup && !isHostInParty && !isHost && !isSingleApp)))
    const showParty = !isSolo || isSingleApp
    
    const [enableAudio, setAudio] = useState(true)
    const [enableVideo, setVideo] = useState(true)

    const toggleAudio = () => setAudio(!enableAudio)
    const toggleVideo = () => setVideo(!enableVideo)

    const [startSession, { data: sessionData, loading: loadingSession }] = useMutation(START_EVENT_SESSION)
    const [recordActivity] = useMutation(RECORD_ACTIVITY)

    const getNextParty = (isHost) => (nextPartyId = hostSelectedParty, targetProfileId) => {
        const { eventId, type: eventType, hosts } = event

        let partyId
        let hostId = undefined

        if (typeof nextPartyId === 'string') {
            partyId = nextPartyId
        } else if (eventType === 'GROUP_EVENT') {
            partyId = '01'
        }

        if (isHost && event.channelId) {
            hostId = hosts && hosts.length > 0 ? hosts[0].hostId : undefined
        }

        const variables = {
          request: {
            appId,
            eventId,
            partyId,
            eventType,
            viewerType,
            hostId,
            targetProfileId
          },
        }

        startSession({ variables })

    }

    useEffect(() => {
        const { eventId } = event
        const activityType = `${'VIEW'}-${viewerType}`
        const variables = {
            request: {
              appId,
              entityId: eventId,
              entityType: 'EVENT',
              activityType
            }
        }
  
        recordActivity({ variables })

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const showSidebar = pollDetails && Object.keys(pollDetails).length > 0

    const isHostInFrontRowView = (isHost || isHostInParty)

    const forceChatOpen = isSolo || (!event.hosts || !event.hosts.length)

    const standardViewConfig = {
        isHostInFrontRowView,
        media: {
            xs: 12,
            sm: 9
        },
        party: {
            xs: 0,
            sm: 3,
        },
        partyContainer: {
            xs: 12,
            sm: 9
        }
    }


    const currentViewConfig = standardViewConfig


    const leaveActiveEvent = () => {
        // setMessengerContext(undefined, undefined, false, undefined, undefined)
        goBack()
    }

    const showFrontRowInvite = (partyDetails && partyDetails.showFrontRowInvite)

    const toggleFrontRowInviteModal = (accept = false) => {

        updateEventDetails(partyDetails.partyId, partyDetails.peers, partyDetails.parties, !partyDetails.showFrontRowInvite)
    
        goToIngress('FRONT_ROW', partyDetails.partyId)
    }

    return (
        <EventContainer>

            <Row >

                <Col xs={showSidebar ? 3 : 0}>
                    <EventSidebar pollDetails={pollDetails} />
                </Col>

                <Col xs={showSidebar ? 9 : 12}>
                    <Row>
                        <Col xs={12} >

                            <ActiveEventHeaderComponent 
                                partyDetails={partyDetails}
                                profile={profile}
                                appConfig={appConfig}
                                event={event}
                                getNextParty={getNextParty(isHost)} 
                                isParty={isParty}
                                currentViewConfig={currentViewConfig}
                                isSolo={isSolo}
                            />
                        </Col>
                    </Row>
                    <div className="d-flex" >
                        
                        { isParty && (
                            <ActiveEventSidebarComponent 
                                goBack={leaveActiveEvent} 
                                event={event} 
                                enableAudio={enableAudio}
                                enableVideo={enableVideo}
                                toggleAudio={toggleAudio}
                                toggleVideo={toggleVideo}
                                localMedia={localMedia}
                            />
                        )}

                        <Row className="w-100" >

                            { showMedia && (
                                <Col xs={currentViewConfig.media.xs} sm={currentViewConfig.media.sm} >
                                    <EventMediaComponent event={event} shouldSync={shouldSyncStream} />
                                </Col>
                            )}

                            { showFrontRowInvite && (
                                <FrontRowInvitationModal 
                                    isOpen={showFrontRowInvite} 
                                    toggle={toggleFrontRowInviteModal}
                                />
                            )}

                            { showParty && (
                                <Col xs={currentViewConfig.partyContainer.xs} sm={currentViewConfig.partyContainer.sm} >
                                    <EventPartyComponent 
                                        {...props} 
                                        enableAudio={enableAudio}
                                        enableVideo={enableVideo}
                                        currentViewConfig={currentViewConfig}
                                        isHost={isHost}
                                        isHostInParty={isHostInParty}
                                        getNextParty={getNextParty(isHost)} 
                                        sessionData={sessionData} 
                                        loadingSession={loadingSession} 
                                    /> 
                                </Col>
                            )}
                        </Row>

                    </div>

                    <EventChatComponent 
                        messengerStyle={{ position: 'inherit', height: '25vh' }}
                        className='d-sm-none mt-3'
                        isChatButtonDisabled={forceChatOpen}
                        showInitial={forceChatOpen}
                        targetId={event.eventId}
                        hideHeader={true}
                    />

                </Col>
            </Row>

    </EventContainer>
    )
}

export default ActiveEventComponent
