import React from 'react'
import EventNavigationComponent from '../../../../event-navigation-component'


const PartySelectorComponent = (props) => {
    const { appConfig, event, partyDetails, profile, goToActiveEvent } = props
    const { eventId } = event
    const { parties } = partyDetails
    const { profileId } = profile

    const goToSpecifiedParty = (hostSelectedParty) => {
        goToActiveEvent(undefined, undefined, hostSelectedParty)
    }

    if (!parties || parties.length ===0) {
        goToActiveEvent(undefined, undefined, 'HOST_FRONT_ROW')
    }

    return (
        <EventNavigationComponent
            isOpen={true}
            toggle={()=>{}}
            appConfig={appConfig}
            currentPartyId={""}
            eventId={eventId}
            goToSpecifiedParty={goToSpecifiedParty}
            myProfileId={profileId}
            parties={parties}
            key={0}
        />
    )
}

export default PartySelectorComponent
