import React, { memo, useState, useCallback } from 'react'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import DndPartyTarget from '../dnd-party-target'
import DndPartySource from '../dnd-party-source'
import update from 'immutability-helper';


const mapPartyPeers = (peers, partyId, myProfileId) => {

    const myPeerProfile = peers.find(peer => peer.profileId === myProfileId)
    let otherPeers = peers.filter(peer => peer.profileId !== myProfileId)
    otherPeers = (otherPeers).map(p => ({ ...p, currentPartyId: partyId }) )        
    
    const response = otherPeers

    if (partyId === 'HOST_FRONT_ROW') {
        response.push({ ...myPeerProfile,
            currentPartyId: 'HOST_FRONT_ROW'
        })
    }
    return response
}


export const DnDContainer = memo( (props) => {

    const { parties, appConfig, myProfileId, setPeerDragged } = props

    const [targets, setTargets] = useState(parties.map(party => 
        ({  accepts: ["ANY"], 
            lastDroppedItem: null, 
            ...party,
            peers: mapPartyPeers(party.peers, party.partyId, myProfileId)
        })
    ))


    const [droppedSourceNames, setDroppedSourceNames] = useState([])

    const isDropped = (sourceName) => {
        return droppedSourceNames.indexOf(sourceName) > -1
    }

    const handleDrop = useCallback((index, transferDetails) => {
        
        const { name, peer, partyId } = transferDetails;
        setDroppedSourceNames(update(droppedSourceNames, name ? { $push: [name] } : { $push: [] }))
        setTargets(update(targets, {
            [index]: {
                lastDroppedItem: {
                    $set: transferDetails,
                },
            },

        }))
        setPeerDragged(partyId, peer)

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [droppedSourceNames, targets])


    const renderSources = (sourcePeers) => (
        <div style={{ overflow: 'hidden', clear: 'both', height: "40px" }}>
            {sourcePeers.map((peer, index) => 
                ( <DndPartySource name={peer.profileId} profileId={peer.profileId} displayName={peer.displayName} 
                    type={"ANY"} isDropped={isDropped(peer.profileId)} key={index} appConfig={appConfig} peer={peer} 
                    myProfileId={myProfileId} />))}
        </div>
    )

    return (
        <div>

            <DndProvider backend={HTML5Backend}>

                <div style={{ overflow: 'hidden', clear: 'both' }}>
                    { targets.map(({ accepts, lastDroppedItem, peers, partyId }, index) => 
                        {
                            return ( <DndPartyTarget accepts={accepts} lastDroppedItem={lastDroppedItem} 
                                onDrop={(item) => handleDrop(index, item)} key={index} appConfig={appConfig} peers={peers} 
                                partyId={partyId} isDropped={isDropped} renderSources={renderSources} /> 
                            )
                        }
                    )}
                </div>

            </DndProvider>
		</div>
    )
})
