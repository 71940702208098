import React from 'react'
import { DragSource } from 'react-dnd'

const style = {
  border: '1px dashed gray',
  backgroundColor: 'white',
  color: 'black',
  padding: '0.5rem 1rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  cursor: 'move',
  float: 'left',
}

const DndPartySource = ({
  peer, isDragging, connectDragSource
}) => {
  const opacity = isDragging ? 0.85 : 1

  return connectDragSource(
    <div ref={connectDragSource} style={{ ...style, opacity }}>
      {peer.displayName}
    </div>
  )
}

export default DragSource(
  'ANY', 
  {
    beginDrag: (props) => ({ name: props.name, peer: props.peer }),
  }, 
  (connect, monitor) => {
    return {
      connectDragSource: connect.dragSource(),
      isDragging: monitor.isDragging(),
    }
  }
)(DndPartySource)



