

const BadgerArt = [
    "badger-builder.png",
    // "badger-mario-back.png",
    "badger-side.png",
    // "digg.jpg",
    "mario-head.png",
    "badger-digg.png",
    "badger-saiya.png",
    "bring-bitcoin-to-defi.png",
    "happy-new-year-logo.png",
    "mario_assets.png",
    "vault-strategists.png",
    // "badger-head-back.png",
    "badger-sett-vision.png",
    "defi-frog-png.png",
    "kong-assets.png",
    "rockman_assets.png",
    "yellow-hat-badger.png"
]

const getLandingArt = () => {
    const index = Math.floor(Math.random() * BadgerArt.length)
    return `https://tes-media-dev.s3.amazonaws.com/content/${BadgerArt[index]}`
}


export const BadgerConfig = {
    ownerProfileId: 'c9ad531de79d6e1a3c4a496bbdb635b71a0ca30f',
    channelIds: ['0a227c86-f736-45ea-858d-984012b35b42'],
    affiliateGroupIds: ['f1065c21-58ad-4e3c-a409-b858f1b63fec'],
    appId: 'BADGER',
    name: 'Badger Finance',
    logo: 'Badger',
    appUrl: 'badger.finance',
    favicon: 'badger-favicon.ico',
    primaryImageUrl: 'https://tes-media-dev.s3.amazonaws.com/content/badger.jpeg',
    pages: [
      'LANDING',
      'EXPLORE',
      'PROFILE',
      'MESSENGER',
    ],
    type: 'CHANNEL_APPS',
    forceSso: true,
    allowStreams: true,
    allowProfileEvents: 'true',
    userTypes: ['ANONYMOUS', 'COGNITO'],
    landing: {
      page: 'LANDING',
      header: 'Virtual Events and Community',
      subHeader: 'BadgerDAO is a decentralized autonomous organization focused on bringing Bitcoin to DeFi',
      heroImageUrl: getLandingArt(),
      cta: {
        primary: {
          text: 'Explore',
          type: 'FUNCTION',
          color: 'primary'
        },
      },
      items: [
        {
          type: 'CONTACT',
          id: 'contact-us',
          isInline: true,
          email: true,
          company: false,
          body: false
        },
        {
          type: 'FOOTER',
          id: 'footer',
        },
      ]
    },
    pageConfigs: [
      {
        key: 'PROFILE',
        isAuthorized: true,
        tabs: [
          'POSTS',
          'EVENTS',
          'FRIENDS',
          'GROUPS',
        ],
      },
      {
        key: 'EXPLORE',
        isAuthorized: false,
        tabs: [
          'EVENTS',
        ],
      },
      {
        key: 'EVENT',
        isAuthorized: false,
        tabs: [],
      },
      {
        key: 'GROUP',
        isAuthorized: true,
        hasEvent: true,
        tabs: [],
      },
    ],
    event: {
      type: 'SINGLE_APP',
      eventId: '67835',
      name: 'Badger Social',
      description: 'A virtual space',
    //   labelOverrideName: "Video",
    //   labelOverridePluralName: "Videos",
      showHeader: false,
      maxPeers: 1000000,
      maxPartySize: 9,
      viewerTypes: {
        allowSolo: true,
        allowParty: true,
        allowFrontRow: false,
        allowHost: true
      }
    },
    theme: {
      components: {
        page: {
          backgroundColor: 'quinary',
          altBackgroundColor: 'octonary',
          backgroundTransparency: 'octonary',
        },
        navBar: {
          backgroundColor: 'quinary',
          logoColor: 'secondary',
          thumbnailContainerColor: 'septenary',
          iconColor: 'primary',
          iconBackgroundColor: 'secondary',
          borderColor: 'primary',
        },
        sideBar: {
          fontColor: 'primary',
          backgroundColor: 'quinary',
          itemComponentColorPassive: 'quinary',
          itemComponentColorActive: 'octonary',
          itemBackgroundColorPassive: 'quinary',
          itemBackgroundColorActive: 'quinary',
          itemColorPassive: 'primary',
          itemColorActive: 'primary',
        },
        hero: {
          titleColor: 'quaternary',
          descriptionColor: 'tertiary',
          bottomBackgroundColor: 'octonary',  
          badgeBackgroundColor: 'octonary',
          badgeFontColor: 'secondary',
          borderColor: 'senary',
          profileImgBackgroundColor: 'octonary',
          profileImgColor: 'secondary'
        },
        gallery: {
          backgroundColor: 'octonary',
          borderColor: 'senary',
          tileLowerBackgroundColor: 'octonary',
          viewCountColor: 'quaternary',
          menuItemColorPassive: 'quaternary',
          menuItemColorActive: 'tertiary',
          menuItemBadgeBackgroundColorPassive: 'quaternary',
          menuItemBadgeBackgroundColorActive: 'tertiary',
          menuItemBadgeColorPassive: 'tertiary',
          menuItemBadgeColorActive: 'octonary',
        }
      },  
      color: {
        primary: '#f2a52b', // yellow
        secondary: '#7D8386', // gray
        tertiary: '#69747C', // slate
        quaternary: '#545454', // dark gray
        quinary: '#222222', // red
        senary: '#22222234', // red w transparency
        septenary: '#f2a52bF34', // yellow w transparency
        octonary: '#7D838640', // gray w transparency
        nonary: '#8666AD', // purple
        
      },
    type: {
        primary: 'Oswald',
        secondary: 'Oswald',
      },
    },
  }
  