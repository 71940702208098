import React from 'react'
import S3ImageComponent from '../s3-image-component'

const BaseLogoComponent = ({ logo, style, goToLanding }) => (
  <span onClick={goToLanding} style={{ cursor: 'pointer', ...style }} className="font-logo my-auto"  >
    { logo }
  </span>
)


const AppLogo = ({ appConfig, appId, logo, goToLanding }) => {
  let style = {}

  console.log({ appConfig })

  let useImageLogo = false

  switch (appId) {
    case 'LIBS_OF_TIKTOK':
      style = { fontSize: '28px', paddingTop: '0.45rem' }
      break
    case 'BADGER':
      style = { fontSize: '28px', paddingTop: '0rem' }
      useImageLogo = true
      break
    case 'SPEAKEASY':
      style = { fontSize: '28px', paddingTop: '0.45rem' }
      break
    case 'TV':
    case 'TESSERACT':
      style = { fontSize: '18px', fontStyle: 'italic', fontWeight: 'bold' }
      break
    case 'NEXTSTEP':
      style = { fontSize: '28px' }
      break
    default:
      break
  }

  if (useImageLogo) {
    return (
      <span onClick={goToLanding} style={{ cursor: 'pointer', ...style }} className="font-logo my-auto"  >
        { appConfig && appConfig.primaryImageUrl && 
          <S3ImageComponent 
            imageName={appConfig.primaryImageUrl} 
            style={{ width: '2.3rem', height: '2.3rem', borderRadius: '100%'}}
            imageStyle={{ borderRadius: '100%' }}
          />
        }
      </span>
    )
  } else {
    return (
      <BaseLogoComponent
        style={style}
        appId={appId}
        logo={logo}
        goToLanding={goToLanding}
      />
    )  
  }
}

export default AppLogo

