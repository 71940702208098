import { useQuery, useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { GET_TEMPLATE_QUERY } from '../active-template-component/queries'
import LoadingComponent from '../loading-component'
import { Col, Row, Card, CardText, CardBody, CardTitle, Collapse, Button } from 'reactstrap'
import BaseFormComponent from '../../shared/base-form'
import { SEARCH_EVENT_REQUEST } from '../../utils/graphql'
import { PUT_TEMPLATE_MUTATION } from './mutations'
import TemplateItemEditorModal from './template-item-editor-modal'

const hasKeys = (obj) => (obj && Object.keys(obj).length > 0)


const mapTemplatePayload = (title, description, contractAddress, templateId, entityType, entityId, hostType, hostId) => ({
    title: `Polling template - ${title}`,
    description: `Polling template - WEIGHTED - ${description}`,
    principal: 'PROFILE',
    scoringPrincipal: 'EVENT',
    allowResubmission: false,
    relativeScoring: false,
    passingScore: 0,
    deliveryType: 'WEBSOCKET',
    notifyEntity: true,
    contractAddress, 
    templateId, 
    entityType, 
    entityId, 
    hostType, 
    hostId,
    scoringType: 'WEIGHTED'
})

const mapTemplateForm = (template) => {

    return {    isInline: false,
        showErrors: true,
        fields: {
            contractAddress: {
                dataType: 'STRING',
                type: 'text',
                name: 'contractAddress',
                label: 'Contract Address',
                showLabel: true,
                defaultValue: hasKeys(template) ? template.contractAddress : '',
                placeholder: '',
                isRequired: true,
                minLength: 42,
                maxLength: 42,
                xs: 12,
            },
        },
        submit: {
        isBlock: true,
        canReSubmit: true,
        text: 'Save'
        },
    }

}

const TemplateEditorComponent = ({ template, entityType, entityId, hostType, hostId, eventTitle, eventDescription }) => {

    let templateId

    const hasExistingTemplate = (template && Object.keys(template).length > 0)

    if (hasExistingTemplate) {
        templateId = template.templateId
    }

    const currentTemplate = hasExistingTemplate ? mapTemplateForm(template) : mapTemplateForm({})
    const templateItems = hasExistingTemplate ? template.templateItems : []
    

    let sortedTemplateItems
    if (templateItems.length > 0) {

        sortedTemplateItems = templateItems.map(item => {
            delete item.__proto__
            return item
        })

        sortedTemplateItems = sortedTemplateItems.sort((a, b) => {
            if (a.key < b.key) { return -1 }
            if (a.key > b.key) { return 1 }
            return 0
        })
    }
    
    const [isMetadaFormOpen, setMetadataFormOpen] = useState(!hasExistingTemplate)
    const [isItemsFormOpen, setItemsFormOpen] = useState(hasExistingTemplate)

    const [isTemplateItemModalOpen, setTemplateItemModalOpen] = useState({ isOpen: false, templateItem: {} })

    const [putTemplate] = useMutation(PUT_TEMPLATE_MUTATION)

    const toggleMetadataForm = () => {
        setMetadataFormOpen(!isMetadaFormOpen)
        setItemsFormOpen(false)
    }

    const toggleItemsForm = () => {
        setItemsFormOpen(!isItemsFormOpen)
        setMetadataFormOpen(false)
    }

    const toggleTemplateItemEditorModal = (templateItem) => {
        console.log({ templateItem })
        setTemplateItemModalOpen({ isOpen: !isTemplateItemModalOpen.isOpen, templateItem })
    }

    const saveTemplate = ({ contractAddress }) => {
        
        const payload = mapTemplatePayload(eventTitle, eventDescription, contractAddress, templateId, entityType, entityId, hostType, hostId)

        const variables = generateVariables(payload)
        putTemplate(variables)    
    }

    const generateVariables = (payload) => ({
        variables: {
            request: {
                ...payload
            }
        },
        refetchQueries: [{
          ...SEARCH_EVENT_REQUEST({ eventId: entityId }),
          fetchPolicy: 'network-only',
        }],    
    })

    return (
        <Row className="my-3" >
            <Col xs={12} >
                <Card className="bg-secondary" >
                    <CardBody>
                        <CardTitle onClick={toggleMetadataForm} >Template Metadata</CardTitle>
                        <Collapse isOpen={isMetadaFormOpen}>
                            <CardText>
                                <Row>
                                    <Col xs={12}>
                                        <BaseFormComponent form={currentTemplate} submit={saveTemplate} />
                                    </Col>
                                </Row>
                            </CardText>
                        </Collapse>

                        <CardTitle onClick={toggleItemsForm} >Template Items</CardTitle>
                        <Collapse isOpen={isItemsFormOpen}>
                            <CardText>
                                <Row>
                                    { sortedTemplateItems && sortedTemplateItems.map( (item, index) => (
                                        <Col xs={12} >
                                            { item.key }) { item.label } { item.inputOptions.map((option, index) => (<> | {option.label}</>))}
                                            <Button className="ml-3" onClick={() => toggleTemplateItemEditorModal(item)}>Edit</Button>
                                        </Col>
                                    ))}
                                    <Col xs={12} className="mt-3" >
                                        <Button onClick={toggleTemplateItemEditorModal}>Create New</Button>
                                    </Col>
                                </Row>
                            </CardText>
                        </Collapse>
                        
                        <TemplateItemEditorModal 
                            isOpen={isTemplateItemModalOpen.isOpen} 
                            toggle={toggleTemplateItemEditorModal} 
                            templateItem={isTemplateItemModalOpen.templateItem} 
                            templateId={templateId}
                            entityId={entityId}
                            newItemKey={templateItems.length + 1}
                        />
                    </CardBody>
                </Card>
            </Col>
        </Row>
    )

}




const TemplateEditorComponentWithQuery = (props) => {

    const { templateId } = props
    
    const variables = {
        request: {
            templateId,
        },
    }
    
    const { data, loading } = useQuery(GET_TEMPLATE_QUERY, { variables })
    
    if (!templateId || templateId.length === 0 || templateId === 'none') {
        return <TemplateEditorComponent {...props} template={{}} />
    } else if (loading) {
        return <LoadingComponent isInline />
    } else {
        console.log({ data })
        const { metadata, results } = data.getTemplate
        const template = metadata.count > 0 ? results[0] : {}
        return <TemplateEditorComponent {...props} template={template} />
    }
}

export default TemplateEditorComponentWithQuery