import React, { useState } from 'react'
import { Button } from 'reactstrap'
import { useQuery, useMutation } from '@apollo/client'
import CryptoService from '@tesseract-media/tesseract-crypto'
import { LINK_CRYPTO_PROFILE_MUTATION } from './mutations'
import { GET_CRYPTO_PROFILE_QUERY } from './queries'
import LoadingComponent from '../loading-component'
import { Modal, ModalHeader, ModalBody, Row, Col, ModalFooter } from 'reactstrap'
import { GET_TEMPLATE_QUERY } from '../active-template-component/queries'

const provider = process.env.REACT_APP_STAGE === 'production' ? 'WEB3' : 'WEB3_TEST'

const CryptoWalletModal = ({toggle, isOpen, cryptoAccount, refresh, contractAddress}) => {

    const [accountBalances, setAccountBalances] = useState([])
    const callRefreshAndClose = () => {
        refresh()
        toggle()
    }

    const initBalances = async () => {
        let mapped = []

        const actualContractAddress = contractAddress ? contractAddress : ""
    
        cryptoAccount.accountIds.map(async id => {
            mapped.push({
                walletAddress: id,
                balance: await CryptoService.getBalance(provider, id, actualContractAddress)
            })
        })

        setAccountBalances(mapped)

        return 
    }
    
    return (
        <Modal isOpen={isOpen} toggle={toggle}>
          <ModalHeader toggle={toggle}>Wallet Info</ModalHeader>
          <ModalBody>
            <Row>
                <Col>
                    AccountIds 
                    <Col>
                        {accountBalances.map(acctBal => {
                            return (<>
                                {acctBal.walletAddress.substring(0,10)}... 
                                Balance {acctBal.balance}
                            </>)
                        })}
                    </Col>
                </Col>
            </Row>
          </ModalBody>
          <ModalFooter>
            <Button block onClick={initBalances}>
                Get Balances
            </Button>
            <Button block onClick={callRefreshAndClose}>
                Link with Browser
            </Button>
          </ModalFooter>
        </Modal>
      )
}


const CryptoWalletLinkComponent = ({profileId, cryptoAccount, template }) => {
    const [linkCryptoProfile] = useMutation(LINK_CRYPTO_PROFILE_MUTATION)

    const [cryptoState, setCryptoState] = useState(cryptoAccount)

    const linkMetaMask = async () => {
        const accounts = await CryptoService.getAccounts(provider)
        const variables = generateVariables(accounts)
        linkCryptoProfile(variables).then(d => setCryptoState(d.data.linkCryptoProfile.results[0]))
    }

   const generateVariables = (accountIds) => ({
        variables: {
            request: {
                accountIds,
                profileId,
                provider,
            }
        },
    })

    const [isOpen, setIsOpen] = useState(false) 

    const toggleModal = () => {
        setIsOpen(!isOpen)
    }

    const buttonText = !cryptoState ? 'Link' : `${cryptoState.accountIds[0].substring(0,6)}...`
    const buttonAction = !cryptoState ? linkMetaMask : toggleModal

    const actualContractAddress = template && template.contractAddress ? template.contractAddress : ""

    return (
        <>
            <CryptoWalletModal
                toggle={toggleModal}
                isOpen={isOpen}
                cryptoAccount={cryptoState}
                refresh={linkMetaMask}
                contractAddress={actualContractAddress} />
            <Button onClick={buttonAction}>
                {buttonText}
            </Button>
        </>
    )
}

const ComponentWithTemplateQuery = (props) => {

    const { event, render } = props

    const queryVariables = {
        request: {
            templateId: event.templateId
        }
    }

    const { data, loading: loadingTemplate } = useQuery(GET_TEMPLATE_QUERY, { variables: queryVariables })
    
    if (loadingTemplate) return <LoadingComponent isInline />

    let template = undefined

    if(data.getTemplate && data.getTemplate.results.length > 0) {
        template = data.getTemplate.results[0]
    }

    return render({ ...props, template })
}

const CryptoWalletLinkComponentWithQuery = (props) => {
    const profileId = props.profileId
    const queryVariables = {
        request: {
            profileId
        }
    }

    const { data: cryptoProfileResult , loading: loadCryptoProfile } = useQuery(GET_CRYPTO_PROFILE_QUERY, { variables: queryVariables })
    
    if (loadCryptoProfile) return <LoadingComponent isInline />

    let cryptoAccount = undefined

    if(cryptoProfileResult.getCryptoProfile && cryptoProfileResult.getCryptoProfile.results.length > 0) {
        cryptoAccount = cryptoProfileResult.getCryptoProfile.results[0]
    }

    return (
        <ComponentWithTemplateQuery {...props}
            render={(props) => (
                <CryptoWalletLinkComponent {...props} cryptoAccount={cryptoAccount}  />
            )}
        />)
}

export default CryptoWalletLinkComponentWithQuery
