import { useQuery } from '@apollo/client'
import React from 'react'
import { Modal, ModalBody, ModalHeader, Input, Row, Col } from "reactstrap"
import LoadingComponent from "../loading-component"
import { GET_SCORECARD_SUBMISSIONS_QUERY } from './queries'

const mapVerification = (sub) => {
    const { address, msg, sig } = sub
    const object = JSON.stringify({
        address,
        sig,
        msg,
        version: "2"
    })

    return object
}


const ScorecardSubmissionModal = ({ submissions, isOpen, toggle }) => {

    return (
        <Modal isOpen={isOpen} toggle={toggle()}>
            <ModalHeader toggle={toggle()}>Results</ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <strong>
                            Wallet
                        </strong>
                    </Col>
                    <Col>
                        <strong>
                            Balance
                        </strong>
                    </Col>
                    <Col>
                        <strong>
                            Answer
                        </strong>
                    </Col>
                    <Col>
                        <strong>
                            Signed
                        </strong>
                    </Col>
                    <Col>

                    </Col>
                </Row>

                <Row>
                    { submissions.map(s => {
                        
                        console.log({ s })
                        const message = JSON.parse(s.msg)

                        const verification = mapVerification(s)

                        return (<>
                                <Col>
                                    { s.address.substring(0,8) + "... " }
                                </Col>
                                <Col>
                                    Bal { message.balance + " " }
                                </Col>
                                <Col>
                                    { message.answer }
                                </Col>
                                <Col>
                                    <Input disabled value={verification} />
                                </Col>
                                <Col>
                                    <a href="https://app.mycrypto.com/verify-message" target="_blank" rel="noopener noreferrer" >Verify</a>
                                </Col>
                        </>)
                    })}
                </Row>

            </ModalBody>
        </Modal>
  
    )
}

const ScorecardSubmissionModalWithQuery = (props) => {
    
    const { templateItemId } = props

    const variables = {
        request: {
            templateItemId,
        },
    }
    
    const { data, loading } = useQuery(GET_SCORECARD_SUBMISSIONS_QUERY, { variables })
    
    if (loading) {
        return <LoadingComponent isInline />
    } else {
        const { metadata, results } = data.getScorecardSubmissions
        const submissions = metadata.count > 0 ? results : []
        return (
            <ScorecardSubmissionModal {...props} submissions={submissions} />
        )
    }

}

export default ScorecardSubmissionModalWithQuery
