/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:83fbee26-2f13-449d-af7b-d2d3108f6c6b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_OSdF7m2PN",
    "aws_user_pools_web_client_id": "1nirebokkkgp11vk6v9luiv9k3",
    "oauth": {},
    "aws_user_files_s3_bucket": "tes-content-bucket102614-dev",
    "aws_user_files_s3_bucket_region": "us-east-1"
};


export default awsmobile;
