import React from 'react'
import { Row, Col, Button } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
import { DnDContainer } from './dnd-container'
import { Modal } from 'reactstrap'


const EventNavigationComponent = ({
  isOpen, toggle, myProfileId, appConfig, parties, goToSpecifiedParty,
}) => {
  // const MAX_PARTIES = 8
  // const MAX_PARTY_SIZE = 6

  const mapParties = (availableParties) => availableParties.map((p, key) => {
    const { partyId } = p
    const currentParty = availableParties.find((p) => p.partyId === partyId)
    return {
      key,
      partyId,
      name: `Party ${key}`,
      peers: (currentParty && currentParty.peers) ? currentParty.peers : [],
    }
  })

  const virtualParties = mapParties(parties)


  if (!isOpen) {
    return <></>
  }

  const setPeerDragged = (newPartyId, peer ) => {
    const { profileId, currentPartyId: peerCurrentPartyId } = peer


    if (peerCurrentPartyId === newPartyId) {
      return
    } else {
      
      goToSpecifiedParty(newPartyId, profileId)

      toggle()
  
      return
    }
  }

  // const isMobile = window.innerWidth < 768

  return (
    <Row style={{ width: '100vw', height: '100vw' }} >
      <Col xs={12} className="text-center">

        <Modal isOpen={isOpen} contentClassName="bg-secondary " >
          <div className="p-3">

            <p className="d-flex">
              <strong className="color-quaternary">
                Group Event Navigator
              </strong>
              <Button color="tertiary" className="ml-auto" onClick={toggle}><FontAwesomeIcon icon={faTimes} /></Button>
            </p>

            <DnDContainer parties={virtualParties} appConfig={appConfig} myProfileId={myProfileId} setPeerDragged={setPeerDragged} />

          </div>

        </Modal>

      </Col>
    </Row>
  )
}

export default EventNavigationComponent
