import React, { useState } from 'react'
import {
  Row, Col, Container, Button
} from 'reactstrap'
import { useMutation } from '@apollo/client'
import YouTube from 'react-youtube'
import LikeComponent from '../../like-component'
import BaseHeroComponent from '../base-hero-component'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEdit } from '@fortawesome/free-solid-svg-icons'
import ReactPlayer from 'react-player'
import { CREATE_BROADCAST_CHANNEL_MUTATION, REMOVE_BROADCAST_CHANNEL_MUTATION } from './mutations'
import { SEARCH_EVENT_REQUEST } from '../../../utils/graphql'
import { addMinutes } from '../../../utils/datetime' 
import EditEventModal from '../../edit-event-modal'

const NativeStreamComponent = ({ currentHost, event }) => {

  const config = {
    file: {
      // forceVideo: true,
      forceHLS: true
      
    },
  }

  const streamToShow = event.isLive ? currentHost.liveStreamUrl : currentHost.streamUrl

  return (
    <ReactPlayer 
      url={streamToShow}
      playing={true}
      controls={true}
      volume={.5}
      width={'100%'}
      config={config}
    />
  )
}  

const YoutubeStreamComponent = ({ currentHost }) => {
  // const isMobile = window.innerWidth < 768
  // const mobileWidth = `${window.innerWidth * 0.9}`
  // https://developers.google.com/youtube/player_parameters

  const opts = {
    // width: isMobile ? mobileWidth : '640',
    width: '100%',
    // height: 'auto',
    playerVars: {
      autoplay: 1,
      playsinline: 1,
    },
  }

  return (
    <YouTube videoId={currentHost.entityId} opts={opts} />
  )
}

const resolveStreamType = (currentHost, event) => {  
  switch (currentHost.streamType) {
    case 'NATIVE':
      return (
        <NativeStreamComponent currentHost={currentHost} event={event} />
      )
    case 'YOUTUBE':
      return (
        <YoutubeStreamComponent currentHost={currentHost} />
      )
    default:
      return (
        <>unable to identify stream type</>
      )
  }
}


const EventHeroComponent = ({ isActive = false, event, isEventOwner, render, myProfileId, authUser, goToEvent }) => {
  const currentHost = event.type === 'PROFILE_EVENT' ? event.hosts.find((h) => h.isPrimary) : {}

  const [startBroadcastChannel] = useMutation(CREATE_BROADCAST_CHANNEL_MUTATION)
  const [stopBroadcastChannel] = useMutation(REMOVE_BROADCAST_CHANNEL_MUTATION)

  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const toggleEditModal = () => setIsEditModalOpen(!isEditModalOpen)

  const { eventId } = event
  const variables = { eventId }
  const refetchQueries = [{
    ...SEARCH_EVENT_REQUEST({ eventId, profileId: myProfileId }),
    fetchPolicy: 'network-only',
  }]

  const startBroadcast = () => startBroadcastChannel({ variables, refetchQueries })
  const stopBroadcast = () => stopBroadcastChannel({ variables, refetchQueries })

  if (isActive) {
    return (
      <>
        <Container>
          <Row className="w-100 mb-2">
            <Col xs={10} className="text-left">
              <strong>
                {' '}
                { event.title }
                {' '}
              </strong>
              {' '}
              <br />
              { event.description }
            </Col>
            <Col xs={2} className="text-right mt-auto">
              <LikeComponent targetId={event.eventId} targetType="EVENT" isLikedInitial={event.isLiked} />
            </Col>
          </Row>
        </Container>
        { resolveStreamType(currentHost, event) }
      </>
    )
  }

  const host = event && event.hosts && event.hosts.length > 0 ? event.hosts.find(h => h.isPrimary) : { status: '', hostDetails: null, ipAddress: '' }
  const { status, ipAddress, hostDetails } = host
  const destination = hostDetails && hostDetails.destinations && hostDetails.destinations.length ? hostDetails.destinations[0] : undefined
  const isHostActive = status && status === 'ACTIVE'
  const isHostPending = status && status === 'PENDING'

  const { startTime, duration, isMediaUpload, hosts } = event

  let endTime

  if (startTime) {
    endTime = addMinutes(startTime, duration+10)
  }

  const showBroadcastControls = isEventOwner && !isMediaUpload && (endTime > new Date()) && hosts.length > 0

  const SecondaryRenderComponent = () => ( 
    <>
      { isEventOwner && (
        <>
          <EditEventModal isOpen={isEditModalOpen} toggle={toggleEditModal} event={event} authUser={authUser} />
          <Button color="primary" className="ml-2" outline onClick={toggleEditModal} >
              <FontAwesomeIcon 
                  color="primary"
                  className="cursor-pointer"
                  icon={faEdit}
              />
          </Button>
          <Button onClick={goToEvent} >Join as Host</Button>

        </>
      )}
    </> 
  )

  return (
    <>
      <BaseHeroComponent
        name={event.title}
        targetId={event.eventId}
        targetType="EVENT"
        isLiked={event.isLiked}
        showLikeToggle={event.showLikeToggle}
        description={event.description}
        imageUrl={event.primaryImageUrl}
        headerImageUrl={event.headerImageUrl}
        primaryRender={render}
        secondaryRender={SecondaryRenderComponent}
        labelText={"Event"}
      />
      { showBroadcastControls && 
        <>
          <Row className="mt-3">
            <Col xs={12} md={6} >
              <Button block color="primary" onClick={startBroadcast} disabled={isHostPending || isHostActive} >Initialize Broadcast Channel</Button>
            </Col>
            <Col xs={12} md={6} >
              <Button block color="secondary" onClick={stopBroadcast} disabled={isHostPending || !isHostActive} >Tear-down Broadcast Channel</Button>
            </Col>
            { isHostPending &&
              <Col xs={12} >
                Broadcast host is in pending status, refresh in about a minute
              </Col>
            }
          </Row>
          { isHostActive &&
            <Row>
              <Col className="mt-3">
                <h3>Stream Details</h3>
              </Col>
              <Col xs={12} >
                Whitelisted IP Address: { ipAddress }
              </Col>
              <Col xs={12} >
                RTMP Server: { destination.Url.substring(0, destination.Url.lastIndexOf('/')) }
              </Col>
              <Col xs={12} >
                Stream Key: { destination.Url.split('/').pop() }
              </Col>

            </Row>}
        </>
      }
    </>
  )
}

export default EventHeroComponent
