import { gql } from '@apollo/client'

export const GET_EVENT_STATE_QUERY = gql`

query ($request: GetEventStateRequest){
    getEventState(request: $request) {
        peers {
            profileId
            displayName
            primaryImageUrl
            channelArn
            isHost
        }
        parties {
            partyId
            peers {
                profileId
                displayName
                primaryImageUrl
                isHost
            }
        }
        viewers {
            profileId
            displayName
            primaryImageUrl
        }
        eventId
    }
}
`
