import React from 'react'
import {
  Modal, ModalHeader, ModalBody, Row, Col,
} from 'reactstrap'
import { useMutation } from '@apollo/client'
// import { PROFILE_EDITS_MUTATION } from './mutations'
// import { GET_SESSION_REQUEST } from '../../utils/graphql'
// import BaseFormComponent from '../../../shared/base-form'
import { TEMPLATE_ITEM_FORM } from './form'
import CompoundFormComponent from '../../../shared/compound-form'
import { PUT_TEMPLATE_ITEM_MUTATION } from '../mutations'
import { SEARCH_EVENT_REQUEST } from '../../../utils/graphql'

// const hasKeys = (obj) => (obj && Object.keys(obj).length > 0)

// const mapTemplateItem = (templateItem) => ({
//     isInline: false,
//     showErrors: true,
//     fields: {
//         type: {
//             dataType: 'STRING',
//             type: 'text',
//             name: 'type',
//             label: 'Item Type',
//             showLabel: true,
//             defaultValue: hasKeys(templateItem) ? templateItem.type : 'QUESTION',
//             placeholder: '',
//             isRequired: true,
//             disabled: true,
//             xs: 12,
//             md: 6
//         },
//         shouldScore: {
//             dataType: 'boolean',
//             type: 'checkbox',
//             name: 'shouldScore',
//             label: 'Should Score',
//             showLabel: true,
//             defaultValue: hasKeys(templateItem) ? templateItem.shouldScore : true,
//             isRequired: true,
//             xs: 12,
//             md: 6
//         },
//         inputType: {
//             dataType: 'STRING',
//             type: 'radio',
//             name: 'inputType',
//             label: 'Item Type',
//             showLabel: true,
//             options: [
//                 {
//                     value: 'TEXT',
//                     label: 'Text Input'
//                 },
//                 {
//                     value: 'RADIO',
//                     label: 'Radio Input'
//                 }
//             ],
//             defaultValue: hasKeys(templateItem) ? templateItem.inputType : '',
//             placeholder: '',
//             isRequired: true,
//             disabled: false,
//             xs: 12,
//             md: 6
//         },
//         answerType: {
//             dataType: 'STRING',
//             type: 'radio',
//             name: 'answerType',
//             label: 'Answer Type',
//             showLabel: true,
//             options: [
//                 {
//                     value: 'STRING',
//                     label: 'String'
//                 },
//                 {
//                     value: 'BOOLEAN',
//                     label: 'Boolean'
//                 },
//                 {
//                     value: 'DECIMAL',
//                     label: 'Number'
//                 }
//             ],
//             defaultValue: hasKeys(templateItem) ? templateItem.answerType : '',
//             placeholder: '',
//             isRequired: true,
//             disabled: false,
//             xs: 12,
//             md: 6
//         },
//         label: {
//             dataType: 'STRING',
//             type: 'text',
//             name: 'label',
//             label: 'Question',
//             showLabel: true,
//             defaultValue: hasKeys(templateItem) ? templateItem.label : '',
//             placeholder: '',
//             isRequired: true,
//             disabled: false,
//             minLength: 5,
//             maxLength: 250,
//             xs: 12,
//         },
//         a: {
//             dataType: 'STRING',
//             type: 'text',
//             name: 'a',
//             label: 'Option A',
//             showLabel: true,
//             defaultValue: hasKeys(templateItem) ? templateItem.inputOptions.find(i => i.key === 1).label : '',
//             placeholder: '',
//             isRequired: true,
//             disabled: false,
//             minLength: 5,
//             maxLength: 250,
//             xs: 12,
//         },
//         b: {
//             dataType: 'STRING',
//             type: 'text',
//             name: 'b',
//             label: 'Option B',
//             showLabel: true,
//             defaultValue: hasKeys(templateItem) ? templateItem.inputOptions.find(i => i.key === 2).label : '',
//             placeholder: '',
//             isRequired: false,
//             minLength: 5,
//             maxLength: 250,
//             xs: 12,
//         },
//         c: {
//             dataType: 'STRING',
//             type: 'text',
//             name: 'c',
//             label: 'Option C',
//             showLabel: true,
//             defaultValue: hasKeys(templateItem) ? templateItem.inputOptions.find(i => i.key === 3).label : '',
//             placeholder: '',
//             isRequired: false,
//             minLength: 5,
//             maxLength: 250,
//             xs: 12,
//         },

//     },
//     submit: {
//         isBlock: true,
//         canReSubmit: true,
//         text: 'Save'
//     },
    
// })




const TemplateItemEditorModal = ({
  isOpen, toggle, templateItem, templateId, entityId, newItemKey
}) => {


    const [putTemplateItem] = useMutation(PUT_TEMPLATE_ITEM_MUTATION)

    const handleEdit = (fields) => {    

        
        let key

        const { A, B, C, D, E, F, answer } = fields

        if (templateItem && templateItem.key) {
            key = templateItem.key
        } else {
            key = newItemKey
        }

        const payload = {
            ...fields,
            key,
            A: `${A}`,
            B: `${B}`,
            C: `${C}`,
            D: `${D}`,
            E: `${E}`,
            F: `${F}`,
            answer: `${answer}`,
            templateId,
        }
        const variables = generateVariables(payload)
        putTemplateItem(variables)    

        toggle()
    }

    const generateVariables = (payload) => ({
        variables: {
            request: {
                ...payload
            }
        },
        refetchQueries: [{
          ...SEARCH_EVENT_REQUEST({ eventId: entityId }),
          fetchPolicy: 'network-only',
        }],    
    })



// const editTemplateItemForm = mapTemplateItem(templateItem)

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Edit Template Item</ModalHeader>
      <ModalBody>
        <Row className="m-2">
            <Col sm={12}>
                <CompoundFormComponent form={TEMPLATE_ITEM_FORM} submit={handleEdit} />
                {/* <BaseFormComponent form={editTemplateItemForm} submit={handleEdit} /> */}
            </Col>
        </Row>

      </ModalBody>
    </Modal>

  )
}

export default TemplateItemEditorModal
