import { useQuery } from '@apollo/client'
import React from 'react'
import { Chart } from 'react-google-charts'
import LoadingComponent from '../loading-component'
import { GET_SCORECARDS_QUERY } from './queries'
import { Col, Row, Card, CardText, CardBody, CardTitle, Badge, Button } from 'reactstrap'
import { GET_TEMPLATE_QUERY } from '../active-template-component/queries'
import ScorecardSubmissionModal from '../scorecard-submissions-modal'
import { useState } from 'react'

const toOrdinance = (value) => {
    var s = String(value),
      len = s.length,
      end  = s.substr(len - 1, 1),
      teen = len > 1 && s.substr(len - 2, 1) === "1",
      ord = "th"
    if (end === "1" && !teen) {
      ord = "st"
    } else if (end === "2" && !teen) {
      ord = "nd"
    } else if (end === "3" && !teen) {
      ord = "rd"
    }
    return `${value}${ord}`
  }



const ScorecardComponent = (props) => {
    const { scorecards, template } = props
    const masterScorecards = scorecards && scorecards.length ? scorecards.filter(s => s && s.masterScorecardId === 'none') : []

    const [isSubModalOpen, setIsSubModalOpen] = useState(false)
    const [subModalTemplateItemId, setSubModalTemplateItemId] = useState("")

    const toggleSubModal = (templateItemId) => () => {
        setSubModalTemplateItemId(templateItemId)
        setIsSubModalOpen(!!templateItemId)
    }

    const results = []

    template.templateItems.forEach(templateItem => {
        const { label, inputOptions, templateItemId } = templateItem
        const itemOptions = []
        
        inputOptions.forEach(inputOption => {
            const scorecardValues = masterScorecards[0].weightedOutcome.filter(o => (o.templateItemId === templateItem.templateItemId && o.answerKey === inputOption.key) )
            let optionValue = 0 

            scorecardValues.forEach(scv => {
                optionValue += scv.totalBalance
            })

            itemOptions.push([`${inputOption.key}) ${inputOption.label}`, optionValue])
        })
        
        results.push({
            title: label,
            itemOptions,
            templateItemId
        })
    })


    if (template.scoringType === 'WEIGHTED') {
        return (
            <>
                <ScorecardSubmissionModal templateId={template.templateId} templateItemId={subModalTemplateItemId} isOpen={isSubModalOpen} toggle={toggleSubModal} />

                { masterScorecards.map(sc => (
                    <div className="mt-3" >
                        <Card className="bg-secondary" >
                            <CardBody>
                                <CardTitle><Badge>Scorecard</Badge></CardTitle>
                                <CardText className="w-100">
                                    <Row>
                                        { results.map(itemResults => (
                                            <Col xs={6} className="text-center" > 
                                                <Row>
                                                    <Col>
                                                        <Chart
                                                            width={'100%'}
                                                            height={'auto'}
                                                            chartType="PieChart"
                                                            loader={<div>Loading Chart</div>}
                                                            data={[
                                                                ['Option', 'Shares'],
                                                                ...itemResults.itemOptions
                                                            ]}
                                                            options={{
                                                                title: itemResults.title,
                                                                backgroundColor: "transparent",
                                                                titleTextStyle: { color: "white" },
                                                                hAxis: { titleTextStyle: { color: "white" } },
                                                                pieHole: 0.4,
                                                                legend: 'none'
                                                            }}
                                                            // rootProps={{ 'data-testid': '3' }}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        { itemResults.itemOptions.map(i => (<p>{ i[0] + " " + i[1] }</p>))}
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs={12}>
                                                        <Button block onClick={toggleSubModal(itemResults.templateItemId)} >View Submissions</Button>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        ))}
                                    </Row>
                                </CardText>
                            </CardBody>
                        </Card>
                    </div>
                ))}
            </>
        )    
    }

    return (
        <>
            { masterScorecards.map(sc => (
                <div className="mt-3" >
                    <Card>
                        <CardBody>
                            <CardTitle><Badge>Scorecard</Badge> { toOrdinance(sc.rank) } place</CardTitle>
                            <CardText>
                                <Row>
                                    <Col xs={12} > Correct Answers: { sc.score } / { sc.scoringItemCount }</Col>
                                    <Col xs={12} > Scoring Group Members </Col>
                                    { sc.profileIds.map(pid => (
                                        <Col xs={12}>{ pid }</Col>
                                    ))}
                                </Row>
                            </CardText>
                        </CardBody>
                    </Card>
                </div>
            )) }
        </>
    )
}


const ComponentWithTemplateQuery = (props) => {

    const { templateId, render } = props

    const queryVariables = {
        request: {
            templateId
        }
    }

    const { data, loading: loadingTemplate } = useQuery(GET_TEMPLATE_QUERY, { variables: queryVariables })
    
    if (loadingTemplate) return <LoadingComponent isInline />

    let template = undefined

    if(data.getTemplate && data.getTemplate.results.length > 0) {
        template = data.getTemplate.results[0]
    }

    return render({ ...props, template })

}


const ScorecardComponentWithQuery = (props) => {

    const { templateId } = props
    
    const variables = {
        request: {
            templateId,
        },
    }
    
    const { data, loading } = useQuery(GET_SCORECARDS_QUERY, { variables })
    
    if (!templateId || templateId.length === 0 || templateId === 'none') {
        return <></>
    } else if (loading) {
        return <LoadingComponent isInline />
    } else {
        const { metadata, results } = data.getScorecards
        const scorecards = metadata.count > 0 ? results : []

        if (scorecards.length > 0 && scorecards[0] !== null) {
            return (
                <ComponentWithTemplateQuery templateId={templateId}
                    render={({ template }) => (
                        <ScorecardComponent {...props} template={template} scorecards={scorecards} />
                )} />
            )
        } else {
            return (<></>)
        }
    }

}

export default ScorecardComponentWithQuery
