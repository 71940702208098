import { useQuery, useMutation } from '@apollo/client'
import React from 'react'
import { Col, Row, Card, CardText, CardBody, CardTitle, Button } from 'reactstrap'
import LoadingComponent from '../loading-component'
import { GET_TEMPLATE_QUERY } from './queries'
import { TEMPLATE_ACTION_MUTATION } from './mutations'


const ActiveTemplateComponent = (props) => {

    const { template } = props

    const { title, templateId, templateItems } = template

    const [templateAction] = useMutation(TEMPLATE_ACTION_MUTATION)

    let sortedTemplateItems

    if (templateItems && templateItems.length > 0) {

        sortedTemplateItems = templateItems.map(item => {
            delete item.__proto__
            return item
        })

        sortedTemplateItems = sortedTemplateItems.sort((a, b) => {
            if (a.key < b.key) { return -1 }
            if (a.key > b.key) { return 1 }
            return 0
        })
    }

    const notifyAction = (templateItemId) => () => {
        const payload = {
            variables: {
                request: {
                    templateId,
                    templateItemId,
                    actionType: 'NOTIFY_PRINCIPALS'
                }
            }
        }
        templateAction(payload)
    }

    const scoreAction = () => {
        const payload = {
            variables: {
                request: {
                    templateId,
                    actionType: 'SCORE_SUBMISSIONS'
                }
            }
        }
        templateAction(payload)
    }
    
    return(
        <div className="mt-3">
            <Card>
                <CardBody>
                    <CardTitle> { title } </CardTitle>
                    <CardText>
                        <Row>
                            { sortedTemplateItems && sortedTemplateItems.map(item => (
                                <Col xs={12} >   
                                    { item.key }) { item.label } { item.inputOptions.map((option, index) => (<> | {option.key}) {option.label}</>))}
                                    <Button className="ml-3" onClick={notifyAction(item.templateItemId)}>Notify Principals</Button>
                                </Col>
                            ))}
                            <Col xs={12} >
                                <Button block className="my-3" onClick={scoreAction}>Generate Scores</Button>
                            </Col>
                        </Row>
                    </CardText>
                </CardBody>
            </Card>

        </div>
    )
}


const ActiveTemplateComponentWithQuery = (props) => {

    const { templateId } = props
    
    const variables = {
        request: {
            templateId,
        },
    }
    
    const { data, loading } = useQuery(GET_TEMPLATE_QUERY, { variables })
    
    if (!templateId || templateId.length === 0 || templateId === 'none') {
        return <ActiveTemplateComponent {...props} template={{}} />
    } else if (loading) {
        return <LoadingComponent isInline />
    } else {
        const { metadata, results } = data.getTemplate
        const template = metadata.count > 0 ? results[0] : {}
        return <ActiveTemplateComponent {...props} template={template} />
    }
}

export default ActiveTemplateComponentWithQuery