import { gql } from '@apollo/client'

export const GET_SCORECARDS_QUERY = gql`
  query getScorecards($request: GetScorecardsRequest) {
    getScorecards(request: $request) {
      results {
        scorecardId
        templateId
        masterScorecardId
        profileIds
        rank
        score
        scoringItemCount
        scoringPrincipal
        scorecardHash
        weightedOutcome {
          totalBalance
          answerKey
          templateItemId
        }
      }
      metadata {
        count
      }
    }
  }
`
