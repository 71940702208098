import { useEffect, useRef, useState } from 'react'
import { getAppId } from '../../../utils/brand-silo'
import { currentMessengerContext, myMessengerContexts, myProfile } from '../../../shared/cache'
import { updateArrayItem } from '../../../shared/cache/util'
import { useReactiveVar } from '@apollo/client'

const ENV_WSS_URL = process.env.REACT_APP_TES_WSS_URL

const notificationContexts = {
  PEER: 'PEER',
  GROUP: 'GROUP',
  PARTY: 'PARTY',
}

const notificationTypes = {
  NEW_MESSAGE: 'NEW_MESSAGE',
}

const EVENT_TYPES = {
  ADD_PEER: 'ADD_PEER',
  REMOVE_PEER: 'REMOVE_PEER',
  INVITE_PEER: 'INVITE_PEER',
  NEW_MESSAGE: 'NEW_MESSAGE',
  GO_TO_SOLO: 'GO_TO_SOLO',
  PEER: 'PEER',
  POLL: 'POLL',
}

const getGroupDisplayName = (profile, groupId) => profile.groups.find((g) => g.groupId === groupId).displayName


const updateEventDetails = (partyDetailsRef, setPartyDetails) => (partyId, peers, parties, showFrontRowInvite = false, shouldMoveUserToSolo = false) => {
  const currentPartyDetails = partyDetailsRef.current

  const newSet = {}

  if (peers && peers.length > 0) {
    peers.map((p) => {
      newSet[p.profileId] = p
      return p
    })  
  } else {
    peers = []
  }

  const listForm = Object.keys(newSet).map((k) => newSet[k])

  setPartyDetails({ ...currentPartyDetails, partyId, peers: listForm, parties, showFrontRowInvite, shouldMoveUserToSolo })
}

const addPeers = (partyDetailsRef, setPartyDetails) => (partyId, peers) => {
  const currentPartyDetails = partyDetailsRef.current

  const newSet = {}

  currentPartyDetails.peers.map((p) => {
    newSet[p.profileId] = p
    return p
  })
  peers.map((p) => {
    newSet[p.profileId] = p
    return p
  })

  const listForm = Object.keys(newSet).map((k) => newSet[k])

  setPartyDetails({ ...currentPartyDetails, partyId, peers: listForm })
}

const newPeerHandler = (msg, partyDetailsRef, setPartyDetails) => {
  const {
    profile: peerProfile,
    parties: peerParties,
    partyId: peerPartyId,
  } = msg

  if (peerPartyId === partyDetailsRef.current.partyId) {
    const {
      channelArn, profileId, displayName, interest, isHost, primaryImageUrl
    } = peerProfile

    const mapped = {
      channelArn,
      profileId,
      displayName,
      interest,
      isHost,
      primaryImageUrl
    }
    console.log({ mapped })
    addPeers(partyDetailsRef, setPartyDetails)(peerPartyId, [mapped])
  }
  const updatedPartyDetails = { ...partyDetailsRef.current, parties: peerParties }
  setPartyDetails(updatedPartyDetails)
}

const removePeerHandler = (msg, partyDetailsRef, setPartyDetails) => {
  const currentPartyDetails = partyDetailsRef.current
  const { profileId } = msg.profile
  const oldSet = currentPartyDetails.peers
  const newSet = oldSet.filter((p) => p.profileId !== profileId)
  const newPartyDetails = { ...partyDetailsRef.current, peers: newSet }
  setPartyDetails(newPartyDetails)
}

const promptFrontRowInvite = (msg, partyDetailsRef, setPartyDetails) => {

  const { partyId } = msg

  const newPartyDetails = { ...partyDetailsRef.current, partyId, showFrontRowInvite: true }
  setPartyDetails(newPartyDetails)

}

const handleGoToSoloView = (msg, partyDetailsRef, setPartyDetails) => {
  const newPartyDetails = { peers: [], parties: [], partyId: '', showFrontRowInvite: false, shouldMoveUserToSolo: true }
  setPartyDetails(newPartyDetails)
}

const newMessageHandler = async (
  msg,
  setNotification,
  profile
) => {
  const {
    text,
    profileId,
    displayName,
    targetId,
    contextType,
    contextHash,
  } = msg.message

  const isMyMessage = (msg.message.profileId === profile.profileId)

  if (isMyMessage) {
    return
  }

  let contextName = ''

  switch (contextType) {
    case 'PEER':
      contextName = displayName
      break
    case 'PARTY':
      contextName = 'Party Chat'
      break
    case 'GROUP':
      contextName = getGroupDisplayName(profile, targetId)
      break
    case 'EVENT':
      contextName = 'Event Chat'
      break
    default:
      break
  }

  const isOpen = true

  const messengerInstance = currentMessengerContext.reactive()

  if ((!messengerInstance || messengerInstance.contextHash !== contextHash) && contextType !== 'EVENT') {
    const actualTargetId = contextType === 'PEER' ? profileId : targetId
    const messengerShape = {
      contextType,
      targetId: actualTargetId,
      isOpen,
      displayName: contextName,
      contextHash,
    }
    const action = async () => {
      currentMessengerContext.reactive(messengerShape)
      setNotification(null)
    }

    const notification = {
      context: notificationContexts.PEER,
      type: notificationTypes.NEW_MESSAGE,
      text,
      senderName: contextName,
      action,
    }

    setNotification(notification)
  } else {

    const allContexts = myMessengerContexts.reactive()
    const context = allContexts.results.find(c => c.contextHash === contextHash)
    const newMessengerState = {
      ...context,
      messages: [
        ...context.messages,
        msg.message,
      ],
    }

    updateArrayItem(myMessengerContexts, newMessengerState)
  }
}

const handleWsMessage = (
  event,
  partyDetailsRef,
  setPartyDetails,
  setNotification,
  setPollDetails,
  profile
) => {
  const { data } = event
  const payload = JSON.parse(data)
  console.log({ payload })
  switch (payload.type) {
    case EVENT_TYPES.ADD_PEER:
      newPeerHandler(payload, partyDetailsRef, setPartyDetails)
      break
    case EVENT_TYPES.REMOVE_PEER:
      removePeerHandler(payload, partyDetailsRef, setPartyDetails)
      break
    case EVENT_TYPES.INVITE_PEER:
      promptFrontRowInvite(payload, partyDetailsRef, setPartyDetails)
      break
    case EVENT_TYPES.GO_TO_SOLO:
      handleGoToSoloView(payload, partyDetailsRef, setPartyDetails)
      break
    case EVENT_TYPES.NEW_MESSAGE:
      newMessageHandler(
        payload,
        setNotification,
        profile
      )
      break
    case EVENT_TYPES.POLL:
      setPollDetails(payload.poll)
      break
    default:
      break
  }
}

const WebsocketGuard = ({
  render,
  partyDetailsRef,
  setPartyDetails,
  setNotification,
  setPollDetails
}) => {
  const linkProfileRef = useRef(() => {})

  const profile = useReactiveVar(myProfile.reactive)

  const [isConnected, setConnected] = useState(false)
  
  useEffect(() => {
    
    if (profile && !isConnected) {

      const wssUrl = `${ENV_WSS_URL}`
      const tesseractSocket = new WebSocket(wssUrl)

      tesseractSocket.onopen = () => {
        const profileId = profile && profile.profileId ? profile.profileId : 'none'
        createConnection({ profileId })
      }

      tesseractSocket.onmessage = (event) => {
        handleWsMessage(
          event,
          partyDetailsRef,
          setPartyDetails,
          setNotification,
          setPollDetails,
          profile
        )
      }

      const createConnection = async ({ profileId, channelArn }) => {
        const type = 'LINK_PROFILE'
        const appId = getAppId()

        const createConnectionMessage = {
          type,
          profileId,
          appId,
          channelArn,
        }

        const msg = `{ "action": "sendMessage", "data": ${JSON.stringify(
          createConnectionMessage,
        )} }`
        tesseractSocket.send(msg)
      }

      linkProfileRef.current = createConnection
      setConnected(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile])

  return render({
    addPeers: addPeers(partyDetailsRef, setPartyDetails),
    updateEventDetails: updateEventDetails(partyDetailsRef, setPartyDetails),
    linkProfileRef
  })
}

export default WebsocketGuard
