import React, { useState } from 'react'
import LandingPage from '../../../pages/landing-page'
import ExploreDirector from './explore-director'
import EventDirector from './event-director'
import ProfileDirector from './profile-director'
import ChannelDirector from './channel-director'
import ApplyDirector from './apply-director'
import NavComponent from '../../nav-component'
import SideBarNavComponent from '../../nav-component/side-bar-nav-component'
import MessengerListModal from '../../messenger-list-modal'
import SettingsModal from '../../settings-modal'
import NotificationComponent from '../../notification-component'
import GroupDetailsPage from '../../../pages/group-details-page'
import AuthorizeComponent from '../../authorize'
import SsoComponent from '../../sso-component'
import ConsentFooter from '../../consent-footer'
import { mapAppEvents } from '../../../utils/mapper'
import { generateUrlPath, isTesseractIdOrNotDev } from '../../../utils/routing'
import SplashPage from '../../splash-page'
import TermsAndAgreementsPage from '../../../pages/terms-and-agreements'
import Scrollable from '../../scrollable'
import { PageContainer, PageContentContainer } from './style'
import FaqPage from '../../../pages/faq-page'


const DIRECTORS = {
  LANDING: 'LANDING',
  EXPLORE: 'EXPLORE',
  EVENT: 'EVENT',
  PROFILE: 'PROFILE',
  AUTH: 'AUTH',
  GROUP: 'GROUP',
  CHANNEL: 'CHANNEL',
  TERMS: 'TERMS',
  PRIVACY: 'PRIVACY',
  FAQ: 'FAQ',
  APPLY: 'APPLY',
}

const determineInitalState = (appData) => {

  const loweredAppId = appData.appId.toLowerCase()
  const { pathname } = window.location
  const params = pathname.split('/')
  params.shift()

  let initialState = {
    path: 'LANDING',
  }
  const isWithoutAppSlug = isTesseractIdOrNotDev(loweredAppId)

  if (!isWithoutAppSlug) {
    params.shift()
  }

  if (params.length > 0) {
  
    switch (params[0].toUpperCase()) {
      case DIRECTORS.EVENT:
        if (params[1]) {
          initialState = {
            path: DIRECTORS.EVENT,
            event: {
              eventId: params[1]
            }
          }
        }
        break
      case DIRECTORS.PROFILE:
        const payload = params[1] 
          ? { profile: {
                profileId: params[1],
                slug: params[1]
            }} 
          : undefined
        initialState = {
          path: DIRECTORS.PROFILE,
          ...payload
        }
        break
      case DIRECTORS.EXPLORE:
        if (params[1]) {
          initialState = {
            path: DIRECTORS.EXPLORE,
            event: {
              eventId: params[1]
            }
          }
        } else {
          initialState = {
            path: DIRECTORS.EXPLORE,
          }
        }
        break
      case DIRECTORS.CHANNEL:
        if (params[1]) {
          initialState = {
            path: DIRECTORS.CHANNEL,
            channel: {
              channelId: params[1],
              slug: params[1]
            }
          }
        }
        break
      case DIRECTORS.TERMS:
        initialState = {
          path: DIRECTORS.TERMS,
        }
        break
      case DIRECTORS.PRIVACY:
        initialState = {
          path: DIRECTORS.PRIVACY,
        }
        break
      case DIRECTORS.FAQ:
        initialState = {
          path: DIRECTORS.FAQ,
        }
        break
      case DIRECTORS.APPLY:
        initialState = {
          path: DIRECTORS.APPLY
        }
        break
      default:
        break
    }
  }

  return initialState
}


const AppDirector = ({
  appData,
  authUser,
  partyDetails,
  addPeers,
  profile,
  setMessengerContext,
  messenger,
  setProfile,
  linkProfileRef,
  notification,
  setNotification,
  appEvents,
  isSessionLoaded,
  clearPartyDetails,
  updateEventDetails,
  pollDetails
}) => {
  
  const isMobile = (window.innerWidth < 769)
  const [isShowingSidebar, setSidebar] = useState(!isMobile)

  const [current, setCurrent] = useState(determineInitalState(appData))

  const [showMessengerList, setShowMessengerList] = useState(false)
  const [showSettingsModal, setShowSettingsModal] = useState(false)

  const toggleMessenger = () => setShowMessengerList(!showMessengerList)

  const toggleSettingsModal = () => setShowSettingsModal(!showSettingsModal)

  const toggleSidebar = () => setSidebar(!isShowingSidebar)

  const goTo = (path, params) => {
    const urlPath = generateUrlPath(appData.appId, path, params, profile, DIRECTORS )
    window.history.pushState('', '', urlPath)
    setCurrent({ ...params, path })
    if (isMobile) {
      setSidebar(false)
    }
  }

  const goToAdapter = (path) => () => goTo(path)

  const goToCta = () => {
    const landing = appData.landing.cta.primary.target
    if (landing === DIRECTORS.EVENT) {
      goTo(DIRECTORS.EVENT, { event: appData.event })
    } else if (landing === DIRECTORS.EXPLORE) {
      goTo(DIRECTORS.EXPLORE)
    } else if (landing === DIRECTORS.PROFILE) {
      goTo(DIRECTORS.PROFILE)
    } else {
      goTo(DIRECTORS.EXPLORE)
    }
  }

  const goToLanding = () => {
    goTo(DIRECTORS.LANDING, {})
  }

  const goToProfile = (profile) => () => {
    // const isLiked = isEventLiked(event.eventId)

    const currentPayload = {
      profile,
    }

    goTo(DIRECTORS.PROFILE, currentPayload)
  }

  const goToEvent = (event) => () => {
    const isLiked = isEventLiked(event.eventId)

    const currentPayload = {
      event: {
        ...event,
        isLiked,
      },
    }

    goTo(DIRECTORS.EVENT, currentPayload)
  }

  const goToEventDetails = (event) => () => {
    const isLiked = isEventLiked(event.eventId)

    const currentPayload = {
      event: {
        ...event,
        isLiked,
      },
    }

    goTo(DIRECTORS.EXPLORE, currentPayload)
  }

  const goToExplore = () => {
    const currentPayload = {
      event: undefined,
    }

    goTo(DIRECTORS.EXPLORE, currentPayload)
  }

  const goToGroupDetails = (group) => () => {
    const currentPayload = {
      group,
    }
    goTo(DIRECTORS.GROUP, currentPayload)
  }

  const goToChannelDetails = (channel) => () => {
    const currentPayload = {
      channel,
    }
    goTo(DIRECTORS.CHANNEL, currentPayload)
  }

  const isEventLiked = (eventId) => {
    if (profile && profile.events) {
      const { events } = profile

      const isLiked = !!events.find((e) => e.eventId === eventId)

      return isLiked
    }
    return false
  }

  const processAppEvents = (items = []) => {
    const isProfileApp = appData.type === 'PROFILE_APPS'
    const processed = []
    const mapped = isProfileApp ? mapAppEvents(items, undefined, profile) : appData.events

    if (profile && profile.events) {
      mapped.map((item) => {
        processed.push({
          ...item,
          isLiked: isEventLiked(item.eventId),
        })
        return item
      })

      return processed
    }
    return mapped
  }

  const resolveView = () => {

    switch (current.path) {
      case DIRECTORS.LANDING:
        return <LandingPage 
                  appConfig={appData} 
                  goToCta={goToCta} 
                  authUser={authUser} 
                  current={current} 
                  setNotification={setNotification} 
                  goTo={goTo}
                />
      case DIRECTORS.EXPLORE:
        return <ExploreDirector 
                  appConfig={appData} 
                  authUser={authUser} 
                  goToEvent={goToEvent} 
                  appEvents={processAppEvents(appEvents)} 
                  profile={profile} 
                  currentEvent={current.event} 
                  goToChannelDetails={goToChannelDetails}
                  setSidebar={setSidebar}
                  goToEventDetails={goToEventDetails}
                  goToExplore={goToExplore}
                />
      case DIRECTORS.EVENT:
        return (
          <AuthorizeComponent
            cognitoUser={authUser}
            page={DIRECTORS.EVENT}
            appData={appData}
            render={() => (
              <EventDirector
                appConfig={appData}
                authUser={authUser}
                profile={profile || current.profile}
                event={current.event}
                partyDetails={partyDetails}
                addPeers={addPeers}
                goToEventDetails={goToEventDetails}
                setMessengerContext={setMessengerContext}
                setProfile={setProfile}
                linkProfileRef={linkProfileRef}
                isSessionLoaded={isSessionLoaded}
                clearPartyDetails={clearPartyDetails}
                updateEventDetails={updateEventDetails}
                goToLanding={goToLanding}
                pollDetails={pollDetails}
                setSidebar={setSidebar}
              />
            )}
          />
        )
      case DIRECTORS.PROFILE:
        return (
          <AuthorizeComponent
            cognitoUser={authUser}
            page={DIRECTORS.PROFILE}
            appData={appData}
            render={() => (
              <ProfileDirector
                authUser={authUser}
                appConfig={appData}
                profile={current.profile ? current.profile : profile}
                messenger={messenger}
                setMessengerContext={setMessengerContext}
                goToEvent={goToEvent}
                goToGroupDetails={goToGroupDetails}
                goToProfile={goToProfile}
                goToChannelDetails={goToChannelDetails}
                goToEventDetails={goToEventDetails}
              />
            )}
          />
        )
      case DIRECTORS.GROUP:
        return (
          <AuthorizeComponent
            cognitoUser={authUser}
            page={DIRECTORS.GROUP}
            appData={appData}
            render={() => (
              <GroupDetailsPage
                group={current.group}
                profile={profile}
                goToEvent={goToEvent}
                setMessengerContext={setMessengerContext}
                authUser={authUser}
                appConfig={appData}
                goToProfile={goToProfile}
              />
            )}
          />
        )
      case DIRECTORS.CHANNEL:
        return (
          <ChannelDirector 
            channel={current.channel}
            appConfig={appData}
            profile={profile}
            goToEvent={goToEvent}
            goToGroupDetails={goToGroupDetails}
            goToEventDetails={goToEventDetails}
            authUser={authUser}
          />
        )
      case DIRECTORS.TERMS:
      case DIRECTORS.PRIVACY:
        return (
          <TermsAndAgreementsPage
            appConfig={appData}
            page={current.path}
          />
        )
      case DIRECTORS.FAQ:
        return (
          <FaqPage
            appConfig={appData}
            page={current.path}
          />
        )
      case DIRECTORS.APPLY:
        return (
          <AuthorizeComponent
            cognitoUser={authUser}
            page={DIRECTORS.PROFILE}
            appData={appData}
            render={() => (
              <ApplyDirector
                appConfig={appData} 
                setNotification={setNotification}
                goToLanding={goToLanding}
              />
            )}
          />
        )
      default:
        return <>Something went wrong</>
    }
  }

  return (
    <>

      <ConsentFooter />

      <SplashPage />
      
      <SsoComponent appConfig={appData} authUser={authUser} />

      <NotificationComponent notification={notification} />

      <SettingsModal
        isOpen={showSettingsModal}
        toggle={toggleSettingsModal}
        appConfig={appData}
      />

      <MessengerListModal
        isOpen={showMessengerList}
        toggle={toggleMessenger}
        profile={profile}
        setMessengerContext={setMessengerContext}
      />

      <NavComponent
        cognitoUser={authUser}
        profile={profile}
        appConfig={appData}
        currentView={current}
        goTo={goToAdapter}
        isShowingSidebar={isShowingSidebar}
        toggleSidebar={toggleSidebar}
        setSidebar={setSidebar}
      />
      <PageContainer>	
        <SideBarNavComponent	
          showNavBar={isShowingSidebar}
          cognitoUser={authUser}	
          appConfig={appData}	
          currentView={current}	
          goTo={goToAdapter}	
          profile={current.profile}	
          toggleMessenger={toggleMessenger}	
          showMessenger={showMessengerList}	
          toggleSettingsModal={toggleSettingsModal}
        />
        <PageContentContainer> 	
          <Scrollable render={resolveView} />
        </PageContentContainer>
      </PageContainer>
    </>
  )
}

export default AppDirector
