
import React from 'react'
import { Modal, ModalHeader, ModalBody } from 'reactstrap'
import { useMutation } from '@apollo/client'
import { CREATE_EVENT_MUTATION } from './mutations'
import BaseFormComponent from '../../shared/base-form'
import { addMinutes, doOverlap } from '../../utils/datetime' 
import { myProfile } from '../../shared/cache'

const addEventToProfile = async (profile, eventPromise) => {
  const newEventPayload = await eventPromise
  const { createAppEvent: newEvent } = newEventPayload.data
  const newState = {
    ...profile,
    postedEvents: [
      newEvent,
      ...profile.postedEvents
    ]
  }
  myProfile.reactive(newState)
}

const addEventToChannel = async (profile, eventPromise) => {
  console.log('todo the right way')
  await eventPromise
  window.location.reload()
}

const CreateEventModal = ({ channelId, toggle, isOpen, profile, products, allowStream }) => {
    const [creatAppEvent] = useMutation(CREATE_EVENT_MUTATION)
    const { postedEvents, hosts } = profile
    const primaryHost = hosts ? hosts.find(h => h) : []

    const validateEventDate = (values) => {
      const { entityId: hostId, startTime: startTimeString, duration, isMediaUpload} = values

      if (isMediaUpload) {
        return false
      }

      const proposedStartTime = new Date(startTimeString)
      const streamEvents = postedEvents ? postedEvents.filter(e => e.streamType === 'NATIVE' && e.entityId === hostId) : []
      const proposedEndTime = addMinutes(proposedStartTime, duration)

      const overlappingEvent = streamEvents.filter(e => {
        const existing = {
          startTime: new Date(e.startTime), 
          endTime: addMinutes(e.startTime, e.duration),
        }

        const proposed = {
          startTime: proposedStartTime, 
          endTime: proposedEndTime,
        }
        const didOverlap = doOverlap(proposed, existing)
          return didOverlap
      })

      return (overlappingEvent.length > 0)
    }

    const createNewEvent = async (formData) => {

      const {
        title, description, startTime, duration, streamType, entityId, isPublic, 
        createIndex, createArchive, allowSolo, allowParties, allowFrontRow, subscriptionTierId, 
        primaryImageUrl, headerImageUrl, isMediaUpload, filePath, isAudioOnlyParty
      } = formData

      let productId = 'Free'
      if (subscriptionTierId !== 'Free') {
        const chosenTierProduct = products.find(p => p.name === subscriptionTierId)
        productId = chosenTierProduct.productId
      }

      const variables = {
        request: {
          title, 
          description, 
          startTime: startTime.toString(),
          duration, 
          channelId,
          streamType, 
          entityId, 
          isPublic, 
          createIndex, 
          createArchive,
          allowSolo,
          allowParties, 
          allowFrontRow, 
          primaryImageUrl, 
          headerImageUrl, 
          isMediaUpload, 
          filePath,
          productId,
          isAudioOnlyParty
        },
      }

      if (!channelId || channelId === 'none') {
        addEventToProfile(profile, creatAppEvent({ variables }))
      } else {
        addEventToChannel(profile, creatAppEvent({ variables }))
      }

      toggle()
    }

    let defaultStartDate = new Date()
    defaultStartDate.setDate(defaultStartDate.getDate() + 1)
    defaultStartDate.setMinutes(0)
    defaultStartDate.setSeconds(0)
    defaultStartDate.setMilliseconds(0)

    let defaultEndDate = new Date()
    defaultEndDate.setDate(defaultEndDate.getDate() + 1)
    defaultEndDate.setHours(defaultEndDate.getHours() + 1)
    defaultEndDate.setMinutes(0)
    defaultEndDate.setSeconds(0)
    defaultEndDate.setMilliseconds(0)


    let tierNames = [ 'Free' ]

    if (products && products.length > 0) {
      products.map(p => tierNames.push(p.name))
    }

    const createEventForm = {
      isInline: false,
      showErrors: true,
      fields: {
        title: {
          dataType: 'STRING',
          type: 'text',
          name: 'title',
          label: 'Title',
          showLabel: true,
          defaultValue: '',
          placeholder: '',
          isRequired: true,
          minLength: 1,
          maxLength: 250,
          xs: 12,
        },
        description: {
          dataType: 'STRING',
          type: 'text',
          name: 'description',
          label: 'Description',
          showLabel: true,
          defaultValue: '',
          placeholder: '',
          isRequired: false,
          minLength: 0,
          maxLength: 1000,
          xs: 12,
        },
        startTime: {
          dataType: 'STRING',
          type: 'datetime',
          name: 'startTime',
          label: 'Start Time',
          showLabel: true,
          defaultValue: defaultStartDate,
          isRequired: false,
          xs: 12,
        },
        duration: {
          dataType: 'NUMBER',
          type: 'number',
          name: 'duration',
          label: 'Duration (15 to 360 minutes)',
          showLabel: true,
          defaultValue: 60,
          isRequired: false,
          min: 1,
          max: 360,
          xs: 12,
          customValidation: allowStream ? validateEventDate : undefined,
          customValidationErrorMessage: "Events cannot overlap for broadcast host"
        },
        isPublic: {
          dataType: 'boolean',
          type: 'checkbox',
          name: 'isPublic',
          label: 'Public Event',
          showLabel: true,
          defaultValue: true,
          isRequired: true,
          hidden: !allowStream,
          xs: 12,
          md: 5
        },
        createIndex: {
          dataType: 'boolean',
          type: 'checkbox',
          name: 'createIndex',
          label: 'Create Index',
          showLabel: true,
          defaultValue: true,
          isRequired: true,
          hidden: !allowStream,
          xs: 12,
          md: 5
        },
        createArchive: {
          dataType: 'boolean',
          type: 'checkbox',
          name: 'createArchive',
          label: 'Create Archive',
          showLabel: true,
          defaultValue: true,
          hidden: !allowStream,
          isRequired: true,
          xs: 12,
          md: 5
        },
        allowSolo: {
          dataType: 'boolean',
          type: 'checkbox',
          name: 'allowSolo',
          label: 'Solo',
          showLabel: true,
          defaultValue: allowStream,
          hidden: true,
          isRequired: true,
          xs: 12,
          md: 5
        },
        allowParties: {
          dataType: 'boolean',
          type: 'checkbox',
          name: 'allowParties',
          label: 'Parties',
          showLabel: true,
          defaultValue: true,
          hidden: !allowStream,
          isRequired: true,
          xs: 12,
          md: 5
        },
        allowFrontRow: {
          dataType: 'boolean',
          type: 'checkbox',
          name: 'allowFrontRow',
          label: 'Front Row',
          showLabel: true,
          defaultValue: false,
          isRequired: true,
          hidden: !allowStream,
          xs: 12,
          md: 5
        },
        isAudioOnlyParty: {
          dataType: 'boolean',
          type: 'checkbox',
          name: 'isAudioOnlyParty',
          label: 'Audio Party',
          showLabel: true,
          defaultValue: true,
          isRequired: true,
          hidden: !allowStream,
          xs: 12,
          md: 5
        },
        isMediaUpload: {
          dataType: 'boolean',
          type: 'checkbox',
          name: 'isMediaUpload',
          label: 'Upload Media',
          showLabel: true,
          defaultValue: false,
          isRequired: true,
          hidden: !allowStream,
          xs: 12,
          md: 5
        },
        subscriptionTierId: {
          dataType: 'STRING',
          type: 'select',
          name: 'subscriptionTierId',
          label: 'Subscription Type',
          showLabel: true,
          options: tierNames,
          defaultValue: 'Free',
          placeholder: '',
          hidden: !allowStream,
          isRequired: true,
          xs: 12,
        },
        streamType: {
          dataType: 'STRING',
          type: 'select',
          name: 'streamType',
          label: 'Stream Type',
          showLabel: true,
          options: [
            'none',
            'NATIVE',
            'YOUTUBE'
          ],
          defaultValue: allowStream ? 'NATIVE' : 'none',
          hidden: !allowStream,
          placeholder: '',
          isRequired: true,
          xs: 12,
        },
        entityId: {
          dataType: 'STRING',
          type: 'text',
          name: 'entityId',
          label: '',
          showLabel: false,
          defaultValue: allowStream ? primaryHost.hostId : 'none',
          hidden: !allowStream,
          isRequired: true,
          minLength: 1,
          maxLength: 200,
          xs: 12,
        },
        primaryImageUrl: {
          dataType: 'IMAGE',
          type: 'image',
          name: 'primaryImageUrl',
          label: 'Profile Picture',
          showLabel: true,
          defaultValue: "none",
          placeholder: "",
          isRequired: true,
          xs: 12,
        },  
        headerImageUrl: {
          dataType: 'IMAGE',
          type: 'image',
          name: 'headerImageUrl',
          label: 'Header Picture',
          showLabel: true,
          defaultValue: "none",
          placeholder: "",
          isRequired: true,
          xs: 12,
        }, 
        filePath: {
          dataType: 'VIDEO',
          type: 'video',
          name: 'filePath',
          label: 'Upload video',
          showLabel: true,
          defaultValue: "none",
          placeholder: "",
          hidden: !allowStream,
          isRequired: true,
          xs: 12,
        },  
      },
      submit: {
        isBlock: true,
      },
    }

    return (
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle}>Create Event</ModalHeader>
        <ModalBody>
          <BaseFormComponent form={createEventForm} submit={createNewEvent} />
        </ModalBody>
      </Modal>
    )
  }


export default CreateEventModal