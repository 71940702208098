import { gql } from '@apollo/client'

export const GET_SCORECARD_SUBMISSIONS_QUERY = gql`
  query getScorecards($request: GetScorecardSubmissionsRequest) {
    getScorecardSubmissions(request: $request) {
      results {
        address
        msg
        sig
      }
      metadata {
        count
      }
    }
  }
`
